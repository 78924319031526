<div class="detail-container">
  <div class="order-content">
    <div class="cargo-storage" *ngIf="draft.cargo_storage"></div>
    <div class="panel panel-default order-detail no-bottom-border" [class.editable]="enabledEdit">
      <div class="panel-heading meta-heading">
        <div class="flex-centered back-box" *ngIf="!userService.isDeliveryManager()"><a routerLink="/drafts" class="flex-centered" role="button"
                                               title="Назад к списку заявок">
          <i class="material-icons nav-icon">keyboard_arrow_left
          </i>
        </a>
        </div>
        <div class="flex-centered back-box" *ngIf="userService.isDeliveryManager() && draft.optimization_task"><a [routerLink]="['/complex-deliveries', draft.optimization_task.id]" class="flex-centered" role="button"
                                               title="Назад к заказу">
          <i class="material-icons nav-icon">keyboard_arrow_left
          </i>
        </a>
        </div>
        <div class="heading-order-info">
          <button type="button" class="save-btn" (click)="onSave()" *ngIf="updated" [disabled]="saving">Сохранить</button>
          <button type="button" class="save-btn cancel-btn" (click)="onCancel()" *ngIf="updated" [disabled]="saving">Отменить</button>

          <span *ngIf="!updated && !hideControls">
            <div class="btn-group">
              <button type="button" class="save-btn" *ngIf="enabledCreateOrder" (click)="onCreateOrder()">Создать заказ</button>
            </div>
            <div class="btn-group" *ngIf="enabledStartSearch">
              <button type="button" class="save-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                Поиск <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li><button type="button" class="btn btn-link" title="Запустить поиск" (click)="onStartSearch()">Запустить поиск</button></li>
                <li *ngIf="scheduledStart"><button type="button" class="btn btn-link" title="Запланировать поиск" (click)="onScheduleTaxiSearch()">Запланировать</button></li>
              </ul>
            </div>
            <button type="button" class="save-btn" *ngIf="enabledScheduleDelivery" (click)="onScheduleDelivery()">Запланировать</button>
            <span class="search-controls">
              <button type="button" class="save-btn cancel-btn" *ngIf="enabledStop" (click)="onStop()"
                      title="Остановить"><span class="glyphicon glyphicon-stop"></span></button>
              <button type="button" class="save-btn cancel-btn" *ngIf="enabledPause" title="Пауза"
                      (click)="onPauseSearch()"><span class="glyphicon glyphicon-pause"></span></button>
              <button type="button" class="save-btn" *ngIf="enabledRestartSearch" (click)="onRestart()"
                      title="Перезапустить"><span class="glyphicon glyphicon-repeat"></span></button>
            </span>
          </span>

          <span *ngIf="draft.id">
            Заявка <strong>#{{ draft.id }}</strong>
            от {{ draft.created_at | dt:'dd.MM.yy H:mm' }}
            <span *ngIf="isTest" class="test-order">[тест]</span></span>
          <span *ngIf="!draft.id">
            Новая заявка
            <span *ngIf="draftType" class="draft-type" (click)="onShowDraftSpecialDialog()">
              [<span *ngIf="!draftType.img" class="icon text">{{ draftType.name }}</span><span *ngIf="draftType.img" class="icon"><img [src]="draftType.img" [alt]="draftType.name" [title]="draftType.name"></span>]
            </span>
          </span>
        </div>
      </div>
      <div class="panel-body meta-body">
        <ng-scrollbar autoHeightDisabled="false" viewClass="scrolled-content">
        <div class="panel-body" [class.locked]="isEditLocked() && !editCity">
          <div class="panel-row">
            <div class="pr-title">Город</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editCity"
              (click)="editCity = switchEditor(editCity)"
            >
              <span *ngIf="!editCity">Редактировать</span>
              <span *ngIf="editCity">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editCity && draft.city">
            <div class="br-box panel-click">
              <div class="pr-body city">
                <div class="city-name">{{ draft.city.name }}</div>
                <div class="city-time-zone">{{ draft.city|cityTz }}</div>
              </div>
              <div class="time-comment shake-horizontal" *ngIf="!(draft.city|myTz)">ниже всё время приведено в часовом поясе города {{ draft.city.name }}</div>
            </div>
          </div>
          <div class="panel-row" *ngIf="editCity">
            <div class="pr-body">
              <city-editor [(city)]="draft.city" (cityChange)="onUpdated()"></city-editor>
            </div>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" *ngIf="draft.delivery">
          <div class="panel-row">
            <div class="pr-title">Статус доставки</div>
          </div>
          <div class="panel-row">
            <div class="br-box panel-click">
              <div class="pr-body">
                {{ draft.delivery_status | deliveryStatus }}
                <span *ngIf="order">(<a
                  [routerLink]="['/orders', order.freighter.id, order.id]">отслеживание заказа #{{ order.id }}</a>)</span>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body" *ngIf="!draft.id && enabledTrackNumber">
          <div class="panel-row">
            <div class="pr-title">Трек-номер</div>
          </div>
          <div class="panel-row">
            <div class="br-box panel-click">
              <div class="pr-body">
                <div>
                  <label><input type="radio" name="trackingService" value="" [(ngModel)]="selectedTrackingService"> не создавать</label>
                </div>
                <div *ngFor="let service of trackingServices">
                  <label>
                    <input type="radio" name="trackingService" [value]="service.identifier" [(ngModel)]="selectedTrackingService"> создать для "{{ service.name }}"
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body" *ngIf="draft.delivery_tracks && draft.delivery_tracks.length > 0">
          <div class="panel-row">
            <div class="pr-title">Трек-номер</div>
          </div>
          <div class="panel-row">
            <div class="br-box">
              <div class="pr-body">
                <div *ngFor="let track of draft.delivery_tracks" class="track-number-container">
                  {{ trackingServiceIdentifierToName(track.service) }}:
                  <span class="track-number" title="трек-номер Mover`а" (click)="onCopyTrackNumber(track.internal_track_number)">{{ track.internal_track_number }}</span>
                  <span *ngIf="track.external_track_number">
                    <span class="glyphicon glyphicon-link"></span>
                    <span class="track-number" title="трек-номер '{{ trackingServiceIdentifierToName(track.service) }}'" (click)="onCopyTrackNumber(track.external_track_number)">{{ track.external_track_number }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body" [class.locked]="isEditLocked() && !editSearchParams" *ngIf="!userService.isDeliveryManager()">
          <div class="panel-row">
            <div class="pr-title">Параметры поиска</div>
            <a
              class="edit-link forced"
              [class.edit-link-editing]="editSearchParams"
              (click)="editSearchParams = switchEditor(editSearchParams)"
            >
              <span *ngIf="!editSearchParams">Редактировать</span>
              <span *ngIf="editSearchParams">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editSearchParams">
            <div class="br-box panel-click" *ngIf="draft.taxi_search?.status != 'search_via_links'">
              <div class="pr-body">
                <div><strong>Автоподтверждение заказа:</strong> <span
                  *ngIf="draft.auto_accept_taxi">да</span><span *ngIf="!draft.auto_accept_taxi">нет</span></div>
              </div>
              <div class="pr-body" *ngIf="enabledExternalSearch">
                <div><strong>Автопоиск такси у партнёров:</strong> <span
                  *ngIf="draft.auto_external_search">да</span><span *ngIf="!draft.auto_external_search">нет</span></div>
              </div>
              <div class="pr-body">
                <div *ngIf="draftLink">
                  <strong>Ссылка на заявку:</strong>
                  <span>
                    <a [href]="draftLink" target="_blank">{{ draftLink }}</a>
                    <button title="Копировать ссылку" type="button" class="btn btn-xs" (click)="onCopyDraftLink()"><span
                      class="glyphicon glyphicon-copy"></span></button>
                  </span>
                </div>
                <div *ngIf="enabledDraftLink && !draftLink && draft.id">
                  <span class="create-link" (click)="onCreateDraftLink()">Создать ссылку на заявку</span>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-row" *ngIf="editSearchParams">
            <div class="pr-body">
              <draft-search-params-editor [draft]="draft" (onUpdated)="onUpdated()" [enabledExternalSearch]="enabledExternalSearch"></draft-search-params-editor>
            </div>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" *ngIf="!draft.taxi_search && order">
          <div class="panel-row">
            <div class="pr-title">Статус поиска</div>
          </div>
          <div class="panel-row">
            <div class="br-box panel-click">
              <div class="pr-body">
                <span *ngIf="order"><a
                  [routerLink]="['/orders', order.freighter.id, order.id]">отслеживание заказа #{{ order.id }}</a></span>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body"
             *ngIf="(!draft.delivery || userService.isPrivilegedUser() || userService.isFreighter()) && (draft.taxi_search || scheduledStart)">
          <div class="panel-row">
            <div class="pr-title">Статус поиска</div>
          </div>
          <div class="panel-row">
            <div class="br-box panel-click">
              <div class="pr-body">
                <span *ngIf="draft.taxi_search">
                  {{ draft.taxi_search.status | taxiSearchStatus }}
                  <span class="search-timer" *ngIf="draft.taxi_search.status == 'search' && searchState">(<span class="timer">{{ searchState.timer | timer }}</span>)</span>
                </span>
                <span *ngIf="order">(<a
                  [routerLink]="['/orders', order.freighter.id, order.id]">отслеживание заказа #{{ order.id }}</a>)</span>
                <div *ngIf="scheduledStart">
                  <span *ngIf="draft.pending_search || (mainState && (draft.taxi_search?.status == 'search' || draft.taxi_search?.status == 'init'))">
                    Поиск машины к {{ scheduledStart | withCityTz:draft.city | dt:'dd.MM.yy H:mm' }}
                  </span>
                  <span *ngIf="draft.manual_search">(требуется ручной запуск)</span>
                </div>
                <div *ngIf="draft.external_freighter" class="external-id">Внешний перевозчик: {{ draft.external_freighter | searchType }}</div>
                <div *ngIf="draft.freighter_external_id" class="external-id">ID заказа у перевозчика: {{ draft.freighter_external_id }}</div>
              </div>
            </div>
          </div>
          <div *ngIf="mainState && (draft.taxi_search?.status == 'search' || draft.taxi_search?.status == 'init')">
            <div class="panel-row">
              <div class="br-box">
                <div class="pr-body search-{{ mainState.search_type }}">
                  <div class="substate" *ngFor="let substate of searchState.states">
                    <table class="table search-info" *ngIf="substate.search_type == 'internal'">
                      <thead>
                      <tr>
                        <th colspan="6" class="section">Внутренний поиск</th>
                      </tr>
                      <tr>
                        <th>Статус</th>
                        <th>Приоритет</th>
                        <th>Шаг</th>
                        <th>Итерация</th>
                        <th>Радиус</th>
                        <th>Найдено</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>{{ substate.status | taxiSearchStatus }}</td>
                        <td>≥ {{ substate.priority }}</td>
                        <td>{{ substate.step }}</td>
                        <td>{{ substate.iteration }}</td>
                        <td>{{ substate.radius }}км</td>
                        <td>{{ substate.found.length }}</td>
                      </tr>
                      </tbody>
                    </table>
                    <table class="table search-info" *ngIf="substate.search_type != 'internal'">
                      <thead>
                      <tr>
                        <th class="section" colspan="2">{{ substate.search_type | searchType }}</th>
                      </tr>
                      <tr>
                        <th>Статус</th>
                        <th>Внешний id</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>{{ substate.status | taxiSearchStatus }}</td>
                        <td>{{ substate.freighter_external_id }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="non-active-search substate" *ngIf="hasNonActiveSubsearches">
                    <table class="table search-info">
                      <thead>
                      <tr>
                        <th class="section" colspan="2">Неактивные поиски</th>
                      </tr>
                      <tr>
                        <th>Тип</th>
                        <th>Статус</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let v of nonActiveSubsearches|mapToIterable">
                        <td>{{ v.key | searchType }}</td>
                        <td>{{ v.val | taxiSearchStatus }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="non-active-search substate" *ngIf="externalExecutions.length > 0">
                    <table class="table search-info">
                      <thead>
                      <tr>
                        <th class="section" colspan="2">Скоро запустятся</th>
                      </tr>
                      <tr>
                        <th>Тип</th>
                        <th>Запуск</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let execution of externalExecutions">
                        <td>{{ execution.freighter.name }}</td>
                        <td>{{ externalSearchExecutionTime(execution) }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="panel-body"
             *ngIf="searchState && (draft.taxi_search?.status == 'search' || draft.taxi_search?.status == 'init')">
          <div class="panel-row">
            <div class="pr-title crews-title">
              <div>Найденные экипажи</div>
              <div class="crews-order" [ngSwitch]="foundCrewsOrder" (click)="onChangeOrderOfFoundCrews()" title="изменить порядок сортировки">
                <span *ngSwitchCase="'foundAt'">по времени нахождения</span>
                <span *ngSwitchDefault>по времени прибытия</span>
              </div>
            </div>
          </div>
          <div class="panel-row">
            <div class="br-box">
              <div class="pr-body">
                <table class="table found-crews">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Водитель</th>
                    <th>Грузчиков</th>
                    <th>Машина</th>
                    <th>Статус</th>
                    <th>Заказ</th>
                  </tr>
                  </thead>
                  <tbody *ngFor="let crew of foundCrewsList.crews; let n = index">
                  <tr class="order-status-{{ crew.order.status }}">
                    <td rowspan="2">{{ n + 1 + (foundCrewsList.page * foundCrewsList.pageSize) }}</td>
                    <td>
                      {{ crew.driver.name }}
                      <div *ngIf="crew.driver.phone">
                        <a *ngIf="userService.isPrivilegedUser()" class="phone" (click)="voximplantService.call(crew.driver.phone)">{{ crew.driver.phone }}</a>
                        <span *ngIf="!userService.isPrivilegedUser()">{{ crew.driver.phone }}</span>
                      </div>
                    </td>
                    <td>{{ crew.loaders }}</td>
                    <td>{{ crew.transport.name }}</td>
                    <td>{{ crew.order.status | orderStatus }}</td>
                    <td rowspan="2">
                      <a [routerLink]="['/orders', crew.order.freighter.id, crew.order.id]">{{ crew.order.id }}</a>
                    </td>
                  </tr>
                  <tr class="order-status-{{ crew.order.status }}">
                    <td colspan="2" class="arrival-time">Прибытие через {{ crew.arrival_time }} мин.</td>
                    <td colspan="2" class="arrival-time">Найден {{ getFoundAt(crew) }} </td>
                  </tr>
                  </tbody>
                </table>
                <pager [list]="foundCrewsList" (onPage)="onChangeFoundCrewsPage($event)" [hideIfNoPages]="true"></pager>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body"
             *ngIf="(!searchState || (draft.taxi_search?.status != 'search' && draft.taxi_search?.status != 'init')) && draftOrdersList.totalCount > 0">
          <div class="panel-row">
            <div class="pr-title">Найденные экипажи</div>
          </div>
          <div class="panel-row">
            <div class="br-box">
              <div class="pr-body">
                <table class="table">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Водитель</th>
                    <th>Машина</th>
                    <th>Статус</th>
                    <th>Заказ</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let order of draftOrdersList.orders; let n = index"
                      class="order-status-{{ order.status }}">
                    <td>{{ n + 1 + draftOrdersList.page * draftOrdersList.pageSize }}</td>
                    <td>
                      {{ order.periods[0].employer.account | fullName }}
                      <div class="phone" *ngIf="order.periods[0].employer.account.phone"><a class="phone"
                                                                                            (click)="voximplantService.call(order.periods[0].employer.account.phone)">{{ order.periods[0].employer.account.phone }}</a>
                      </div>
                    </td>
                    <td>{{ order.periods[0].transport | transportDescription }}</td>
                    <td>{{ order.status | orderStatus }}</td>
                    <td>
                      <a [routerLink]="['/orders', order.freighter.id, order.id]">{{ order.id }}</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <pager [list]="draftOrdersList" (onPage)="onChangeOrdersPage($event)" [hideIfNoPages]="true"></pager>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body client" *ngIf="enabledClientEdit" [class.locked]="isEditLocked() && !editClient">
          <div class="panel-row">
            <div class="pr-title">Клиент</div>
            <a
              *ngIf="!draft.id"
              class="edit-link"
              [class.edit-link-editing]="editClient"
              (click)="editClient = switchEditor(editClient)"
            >
              <span *ngIf="!editClient">Редактировать</span>
              <span *ngIf="editClient">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editClient">
            <table class="table tbl-order">
              <thead>
              <tr>
                <td>Имя</td>
                <td>Телефон</td>
                <td *ngIf="draft.client.company_client">Компания</td>
                <td *ngIf="draft.client.id">Промокод</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ draft.client | fullName }}</td>
                <td><a class="phone" *ngIf="draft.client.phone"
                       (click)="voximplantService.call(draft.client.phone)">{{ draft.client.phone }}</a></td>
                <td *ngIf="draft.client.company_client">{{ draft.client.company_client.name }}</td>
                <td>
                  <span class="enter-promo-code" (click)="onOpenPromoCodeDialog()" *ngIf="draft.client.id && !promoCode">Ввести промокод</span>
                  <span class="promo-code entered-promo-code" *ngIf="promoCode && promoCode.type == 'entered'" (click)="onOpenPromoCodeDialog()">{{ promoCode.code }}</span>
                  <span class="promo-code applied-promo-code" *ngIf="promoCode && promoCode.type == 'applied'">{{ promoCode.code }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-row" *ngIf="editClient">
            <div class="pr-body">
              <client-editor [client]="draft.client" (onUpdated)="onUpdatedClient()"></client-editor>
            </div>
          </div>
          <div *ngIf="userService.isPrivilegedUser()" class="intercom">
            <div class="view panel-row" *ngIf="!editIntercomDialog">
              <div class="link">
                <div *ngIf="draft.intercom_dialog"><a href="{{ draft.intercom_dialog }}" target="_blank"><span
                  class="glyphicon glyphicon-comment"></span> Диалог в интеркоме</a></div>
                <div *ngIf="!draft.intercom_dialog"><span class="glyphicon glyphicon-comment"></span> Нет ссылки на
                  диалог
                </div>
              </div>
              <div class="control">
                <a (click)="editIntercomDialog = true">
                  <span *ngIf="!draft.intercom_dialog">Добавить</span>
                  <span *ngIf="draft.intercom_dialog">Изменить</span>
                </a>
              </div>
            </div>
            <div *ngIf="editIntercomDialog">
              <form (submit)="onSaveIntercomDialogLink()" class="panel-row">
                <div class="input">
                  <input name="intercomDialog" [(ngModel)]="draft.intercom_dialog" class="form-control"
                         placeholder="Ссылка на диалог в интеркоме">
                </div>
                <div>
                  <button type="submit" class="btn btn-primary">Сохранить</button>
                </div>
                <div>
                  <button type="button" class="btn btn-default" title="Отменить" (click)="editIntercomDialog = false">
                    <span class="glyphicon glyphicon-remove"></span></button>
                </div>
              </form>
            </div>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body client" *ngIf="enabledFreighterClient" [class.locked]="isEditLocked() && !editFreighterClient">
          <div class="panel-row">
            <div class="pr-title">Клиент</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editFreighterClient"
              (click)="editFreighterClient = switchEditor(editFreighterClient)"
            >
              <span *ngIf="!editFreighterClient">Редактировать</span>
              <span *ngIf="editFreighterClient">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editFreighterClient">
            <table class="table tbl-order">
              <thead>
              <tr>
                <td>Наименование</td>
                <td>Контактное лицо</td>
                <td>Контактный телефон</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ draft.freighter_client?.name }}</td>
                <td>{{ draft.freighter_client?.contact_name }}</td>
                <td><span *ngIf="draft.freighter_client?.contact_phone">{{ draft.freighter_client.contact_phone|formatPhone }}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-row" *ngIf="editFreighterClient">
            <div class="pr-body">
              <freighter-client-editor [(client)]="draft.freighter_client" [freighter]="freighter" (clientChange)="onUpdatedFreighterClient()"></freighter-client-editor>
            </div>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body client" *ngIf="enabledLegalEntityEdit"
             [class.locked]="isEditLocked() && !editLegalEntity">
          <div class="panel-row">
            <div class="pr-title">Юридическое лицо</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editLegalEntity"
              (click)="editLegalEntity = switchEditor(editLegalEntity)"
            >
              <span *ngIf="!editLegalEntity">Редактировать</span>
              <span *ngIf="editLegalEntity">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editLegalEntity">
            <div class="br-box panel-click">
              <div class="pr-body" *ngIf="draft.legal_entity">
                <span *ngIf="!showLegalEntityDetails" class="legal-entity-name" (click)="showLegalEntityDetails = true"
                      title="Показать платёжные реквизиты">{{ draft.legal_entity.name }}</span>
                <legal-entity-detail *ngIf="showLegalEntityDetails" [legalEntity]="draft.legal_entity"
                                     [compact]="true"></legal-entity-detail>
              </div>
            </div>
          </div>
          <div class="panel-row" *ngIf="editLegalEntity">
            <div class="pr-body">
              <legal-entity-editor [company]="activeCompanyClient" [(entity)]="draft.legal_entity"
                                   (entityChange)="onUpdated()"></legal-entity-editor>
            </div>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" *ngIf="draft.delivery" [class.locked]="isEditLocked() && !editSchema">
          <div class="panel-row">
            <div class="pr-title">Схема доставки</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editSchema"
              (click)="editSchema = switchEditor(editSchema)"
            >
              <span *ngIf="!editSchema">Редактировать</span>
              <span *ngIf="editSchema">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editSchema">
            <div class="br-box panel-click">
              <div class="pr-body"> {{ draft.delivery_schema?.name }}</div>
            </div>
          </div>
          <div class="panel-row" *ngIf="editSchema">
            <draft-delivery-schema-editor [draft]="draft" (onUpdated)="onDeliverySchemaUpdated()"></draft-delivery-schema-editor>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" *ngIf="enabledOwnTariffs" [class.locked]="isEditLocked() && !editTariff">
          <div class="panel-row">
            <div class="pr-title">Тарифы</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editTariff"
              (click)="editTariff = switchEditor(editTariff)"
            >
              <span *ngIf="!editTariff">Редактировать</span>
              <span *ngIf="editTariff">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editTariff">
            <div *ngFor="let tariff of draft.tariffs">
              <table class="table tariff-table">
                <caption class="caption-in-table">Транспорт</caption>
                <thead>
                <tr>
                  <th>Минимум</th>
                  <th>Доп. час</th>
                  <th>км за МКАД</th>
                  <th>Въезд в ТТК</th>
                  <th>Гидролифт</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ tariff.min_price }} / {{ tariff.min_hours }} ч</td>
                  <td>{{ tariff.additional_hour_price }}</td>
                  <td>{{ tariff.after_mkad_km_price }}</td>
                  <td>{{ tariff.ttk_price }}</td>
                  <td>{{ tariff.hydroelevator_hour_price }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="panel-row" *ngIf="!editTariff">
            <table class="table tariff-table" *ngFor="let tariff of draft.loader_tariffs" cols="5">
              <caption class="caption-in-table">Грузчики</caption>
              <thead>
              <tr>
                <th>Минимум</th>
                <th>Доп. час</th>
                <th>Час в пути</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ tariff.min_price }} / {{ tariff.min_hours }} ч
                </td>
                <td>{{ tariff.additional_hour_price }}</td>
                <td>{{ tariff.on_the_way_hour_price }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-row" *ngIf="!editTariff">
            <table class="table tariff-table" cols="5" *ngFor="let tariff of draft.assembler_tariffs">
              <caption class="caption-in-table">Сборщики</caption>
              <thead>
              <tr>
                <th>Минимум</th>
                <th>Доп. час</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ tariff.min_price }} / {{ tariff.min_hours }} ч</td>
                <td>{{ tariff.additional_hour_price }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-row" *ngIf="!editTariff">
            <table class="table tariff-table" *ngFor="let tariff of draft.lifting_tariffs" cols="5">
              <caption class="caption-in-table">Подъём/спуск</caption>
              <thead>
              <tr>
                <th>Минимум</th>
                <th>Доп. час</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ tariff.min_price }} / {{ tariff.min_hours }} ч</td>
                <td>{{ tariff.additional_hour_price }}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-row" *ngIf="editTariff">
            <draft-tariff-editor [draft]="draft" (updated)="onUpdated()"></draft-tariff-editor>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" *ngIf="draft.delivery" [class.locked]="isEditLocked() && !editSchedule">
          <div class="panel-row">
            <div class="pr-title">Расписание доставки</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editSchedule"
              (click)="editSchedule = switchEditor(editSchedule)"
            >
              <span *ngIf="!editSchedule">Редактировать</span>
              <span *ngIf="editSchedule">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editSchedule">
            <schedule-view *ngIf="draft.delivery_schedule" [schedule]="draft.delivery_schedule" [city]="draft.city"></schedule-view>
            <span *ngIf="!draft.delivery_schedule">не задано</span>
          </div>
          <div class="panel-row" *ngIf="editSchedule">
            <draft-schedule-editor [draft]="draft" (onUpdated)="onUpdated()"></draft-schedule-editor>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" [class.locked]="isEditLocked() && !editPay">
          <div class="panel-row">
            <div class="pr-title">Оплата</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editPay"
              (click)="editPay = switchEditor(editPay)"
            >
              <span *ngIf="!editPay">Редактировать</span>
              <span *ngIf="editPay">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editPay">
            <div class="br-box panel-click">
              <div class="pr-body">
                <table class="table tbl-order data-table">
                  <thead>
                  <tr>
                    <td>Тип оплаты</td>
                    <td>Стоимость</td>
                    <td *ngIf="showDiscount">Скидка фикс</td>
                    <td *ngIf="showDiscount">Скидка %</td>
                    <td *ngIf="showDiscount">Стоимость с учётом скидок</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      {{ draft.pay_method | payMethod }}
                      <div class="pay-method-option" *ngIf="draft.pay_method_option">({{ draft.pay_method_option|payMethodOption }})</div>
                      <div class="pay-method-option" *ngIf="freighter">
                        Распределение средств:
                        <span *ngIf="paymentDistributionSchema">{{ paymentDistributionSchema.name }}</span>
                        <span *ngIf="!paymentDistributionSchema">по умолчанию</span>
                      </div>
                    </td>
                    <td>{{ draft.cost }} Р</td>
                    <td *ngIf="showDiscount">{{ draft.fixed_discount }} Р</td>
                    <td *ngIf="showDiscount">{{ draft.percent_discount }} %</td>
                    <td *ngIf="showDiscount">{{ draft.total_cost }} Р</td>
                  </tr>
                  </tbody>

                </table>

              </div>
            </div>
          </div>
          <div class="panel-row" *ngIf="!editPay && draft.calculation && draft.calculation.length !== 0">
            <div class="br-box panel-click">
              <div class="pr-title">
                Калькуляция
                <a
                  class="btn btn-xs btn-default"
                  (click)="showCalculation = !showCalculation"
                >
                  <span *ngIf="!showCalculation" class="glyphicon glyphicon-chevron-up"></span>
                  <span *ngIf="showCalculation" class="glyphicon glyphicon-chevron-down"></span>
                </a>
              </div>
              <div class="pr-body" *ngIf="showCalculation">
                <calculation [calculation]="draft.calculation"
                             [destinations]="draft.destinations"
                             [perMinuteBilling]="draft.calculation['per_minute_billing'] || false"></calculation>
              </div>
            </div>
          </div>
          <div class="panel-row" *ngIf="editPay">
            <draft-pay-editor [draft]="draft" (onUpdated)="onUpdated()"></draft-pay-editor>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" [class.locked]="isEditLocked() && !editInsurance" *ngIf="enabledInsurance">
          <div class="panel-row">
            <div class="pr-title">Страховка</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editInsurance"
              (click)="editInsurance = switchEditor(editInsurance)"
            >
              <span *ngIf="!editInsurance">Редактировать</span>
              <span *ngIf="editInsurance">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editInsurance">
            <div class="br-box panel-click">
              <div class="pr-body">
                <table class="table tbl-order data-table insurances">
                  <caption *ngIf="draft.insurances.length == 0">Груз не застрахован</caption>
                  <thead *ngIf="draft.insurances.length > 0">
                  <tr>
                    <td>Тип страховки</td>
                    <td>Груз</td>
                    <td>Стоимость груза</td>
                    <td>Платёж</td>
                  </tr>
                  </thead>
                  <tbody *ngIf="draft.insurances.length > 0">
                  <tr *ngFor="let insurance of draft.insurances">
                    <td>{{ insurance.section|insuranceSection }}</td>
                    <td>{{ insurance.cargo_description }}</td>
                    <td>{{ insurance.cargo_cost }}<span *ngIf="insurance.cargo_cost > 0"> Р</span></td>
                    <td>{{ insurance.client_insurance_price }}<span *ngIf="insurance.client_insurance_price > 0"> Р</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="panel-row" *ngIf="editInsurance"><draft-insurance-editor [draft]="draft" (onUpdated)="onUpdated()"></draft-insurance-editor></div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" [class.locked]="isEditLocked() && !editRoute">
          <div class="panel-row">
            <div class="pr-title">Маршрут</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editRoute"
              (click)="editRoute = switchEditor(editRoute)"
            >
              <span *ngIf="!editRoute">Редактировать</span>
              <span *ngIf="editRoute">Отменить</span>
            </a>
          </div>

          <div *ngIf="!editRoute">
            <div class="panel-row address-row" *ngFor="let destination of draft.destinations; let i = index">
              <div class="caption full-width">Адрес {{ i + 1 }}</div>
              <div class="full-width">
                <span *ngIf="destination.time_slot_begin && destination.time_slot_end"
                      title="Тайм-слот">[{{ destination.time_slot_begin | withCityTz:draft.city | dt:'H:mm' }}
                  -{{ destination.time_slot_end | dt:'H:mm' }}] - </span>
                <span *ngIf="destination.arrival_time"
                      title="Время прибытия">{{ destination.arrival_time | withCityTz:draft.city | dt:'H:mm' }} - </span>
                <a href="#"
                   (click)="focusMap(destination.destination.lat, destination.destination.lon)">
                  {{ destination.destination.addr }}
                </a>
              </div>
              <div *ngIf="destination.client_legal_entity" class="legal-entity">
                <div class="title">Юридическое лицо</div>
                <span *ngIf="!isVisibleClientLegalEntityDetailsInDestination(destination)" class="legal-entity-name"
                      (click)="onShowClientLegalEntityDetailsInDestination(destination)"
                      title="Показать платёжные реквизиты">
                  {{ destination.client_legal_entity.name }}
                </span>
                <legal-entity-detail *ngIf="isVisibleClientLegalEntityDetailsInDestination(destination)"
                                     [legalEntity]="destination.client_legal_entity"
                                     [compact]="true"></legal-entity-detail>

              </div>
              <div class="address-info">
                <div>
                  <span *ngIf="destination.arrival_time || destination.arrival_time_end">прибытие</span>
                  <span
                    *ngIf="destination.arrival_time"><strong>{{ destination.arrival_time | withCityTz:draft.city | dt:'dd.MM.yy H:mm' }}</strong></span>
                  <span
                    *ngIf="destination.arrival_time_end"> &ndash;
                    <strong>{{ destination.arrival_time_end | withCityTz:draft.city | dt:'H:mm' }}</strong>
                  </span>
                  <span *ngIf="destination.floor">,{{ destination.floor }} этаж</span>
                  <span *ngIf="destination|destinationOptions">, {{ destination|destinationOptions }}</span>
                  <span class="text-muted" *ngIf="destination.contact_name || destination.contact_phone || destination.is_hidden_contacts">
                    <span *ngIf="destination.contact_name">{{ destination.contact_name }}</span>
                    <span *ngIf="destination.contact_phone">{{ destination.contact_phone }}</span>
                    <span *ngIf="destination.is_contacts_hidden">телефон скрыт</span>
                    <span *ngIf="destination.contact_send_sms">, отправить смс-уведомление</span>
                  </span>
                </div>
                <delivery-product-wrapper *ngIf="destination.delivery_product"
                                          [product]="destination.delivery_product"></delivery-product-wrapper>
                <div class="text-muted" *ngIf="destination.comment">
                  <em class="pre-wrap">{{ destination.comment }}</em>
                </div>
              </div>
            </div>

          </div>
          <div class="panel-row no-bottom-border" *ngIf="editRoute">
            <draft-route-editor [draft]="draft"
                                [legalEntitySelectorDialog]="legalEntitySelectorDialog"
                                (onFocusedAddr)="onFocusAddrField($event)"
                                (onUpdated)="onRouteUpdated()"
                                (onImported)="onImported()"
                                (onRequestDeliveryProductDialog)="onShowDeliveryProductDialog($event)"
            ></draft-route-editor>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" [class.locked]="isEditLocked() && !editTransportFeatures" *ngIf="!draft.optimization_task">
          <div class="panel-row">
            <div class="pr-title">Параметры транспорта</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editTransportFeatures"
              (click)="editTransportFeatures = switchEditor(editTransportFeatures)"
            >
              <span *ngIf="!editTransportFeatures">Редактировать</span>
              <span *ngIf="editTransportFeatures">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editTransportFeatures">
            <div class="br-box panel-click">
              <div class="pr-body">
                <div *ngIf="draft.extra_search_params">
                  <strong>Тариф:</strong> {{ draft.extra_search_params.tariff_tier?.name }}</div>
                <div><strong>Грузоподъёмности:</strong> {{ draft | draftCapacities }}</div>
                <div><strong>Кузова:</strong> {{ draft | draftBodyTypes }}</div>
                <div *ngIf="draft.extra_search_params && draft.extra_search_params.length_groups">
                  <strong>Группы длин:</strong>
                  <span
                    *ngIf="draft.extra_search_params.length_groups.length > 0">{{ draft.extra_search_params.length_groups.join(' м., ') }}
                    м.</span>
                  <span *ngIf="draft.extra_search_params.length_groups.length == 0">любые</span>
                </div>
                <div><strong>Реальные длины:</strong> {{ draft | draftLengths }}</div>
              </div>
              <div><strong>Требуется гидролифт:</strong> {{ draft | draftHydroElevator }}</div>
              <div *ngIf="draft.extra_search_params">
                <table class="min-transport-params">
                  <caption>Минимальные параметры транспорта</caption>
                  <thead>
                  <tr>
                    <th>Грузоподъёмность, т</th>
                    <th>Длина, м</th>
                    <th>Ширина, м</th>
                    <th>Высота, м</th>
                    <th>Объём, м<sup>3</sup></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ (draft.extra_search_params.min_capacity | humanNumber: null) || '∞' }}</td>
                    <td>{{ (draft.extra_search_params.min_length | humanNumber: null)  || '∞' }}</td>
                    <td>{{ (draft.extra_search_params.min_width | humanNumber: null)  || '∞' }}</td>
                    <td>{{ (draft.extra_search_params.min_height | humanNumber: null)  || '∞' }}</td>
                    <td>{{ (draft | draftTransportParameter:"Ob_em_m3" | humanNumber: null) || '∞' }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="panel-row" *ngIf="editTransportFeatures">
            <draft-transport-features-editor [draft]="draft" [updated]="updatedTransportFeatures" [point]="tierPoint"
                                             (onUpdated)="onUpdatedTransportFeatures()"></draft-transport-features-editor>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" [class.locked]="isEditLocked() && !editFreighterFilters"
             *ngIf="enabledFreighterFilters">
          <div class="panel-row">
            <div class="pr-title">Перевозчики</div>
            <a
              class="edit-link forced"
              [class.edit-link-editing]="editFreighterFilters"
              (click)="editFreighterFilters = switchEditor(editFreighterFilters)"
            >
              <span *ngIf="!editFreighterFilters">Редактировать</span>
              <span *ngIf="editFreighterFilters">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editFreighterFilters">
            <div class="br-box panel-click">
              <div class="pr-body">
                <div class="filters">
                  <div class="all" *ngIf="draft.freighter_filters.length == 0 && draft.employer_filters.length == 0 && draft.crew_filters.length == 0">
                    поиск по всем перевозчикам MOVER
                  </div>
                  <div class="selected" *ngIf="draft.freighter_filters.length > 0 || draft.employer_filters.length > 0 || draft.crew_filters.length > 0">
                    <div class="freighter item" *ngFor="let f of draft.freighter_filters">
                      <span class="name" [attr.title]="externalSearchStartedAt(f)">{{ f.freighter.name }}</span>
                    </div>
                    <div class="employer item" *ngFor="let e of draft.employer_filters">
                      <span class="name">{{ e.employer.account|fullName }}</span>
                    </div>
                    <div class="crew item" *ngFor="let c of draft.crew_filters">
                      <span class="name">{{ c.crew.employer.account.surname }} / {{ c.crew.transport|transportDescription }}</span>
                    </div>
                  </div>
                </div>
                <div class="auto-assigment-crew" *ngIf="draft.auto_assigment_crew">
                    <span class="glyphicon glyphicon-check ok"></span> Включено автоназначение экипажа.
                </div>
              </div>
            </div>
          </div>
          <div class="panel-row" *ngIf="editFreighterFilters">
            <freighter-filters-editor [freightersListDialog]="freightersListDialog" [draft]="draft"
                                      [replaceEmployerWithCrew]="!!freighter"
                                      (saved)="onSaveFreighterFilters($event)"></freighter-filters-editor>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" [class.locked]="isEditLocked() && !editParameters">
          <div class="panel-row">
            <div class="pr-title">Параметры заказа</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editParameters"
              (click)="editParameters = switchEditor(editParameters)"
            >
              <span *ngIf="!editParameters">Редактировать</span>
              <span *ngIf="editParameters">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editParameters">
            <div class="col-lg-12 no-padding">
              <order-areas-info *ngIf="draft.areas" [areas]="draft.areas"></order-areas-info>
              <table class="table tbl-order loaders-params">
                <thead>
                <tr>
                  <!--<td>Время работы</td>-->
                  <td>Суммарное расстояние</td>
                  <td *ngIf="draft.out_mkad_distance > 0">За МКАД</td>
                  <td>Грузчики</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <!--<td>{{ draft.minutes / 60 || 0 | round }} ч</td>-->
                  <td>{{ draft.distance / 1000 || 0 }} км</td>
                  <td *ngIf="draft.out_mkad_distance > 0">{{ draft.out_mkad_distance / 1000 }} км</td>
                  <td>{{ draft.loaders || 0 }}<span *ngIf="loadersParameters.length > 0"> ({{ loadersParameters.join(', ') }})</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="panel-row" *ngIf="editParameters">
            <draft-parameters-editor [draft]="draft" (onUpdated)="onUpdated()"></draft-parameters-editor>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" [class.locked]="isEditLocked() && !editPhotos">
          <div class="panel-row">
            <div class="pr-title">Фотографии</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editPhotos"
              (click)="editPhotos = switchEditor(editPhotos)"
            >
              <span *ngIf="!editPhotos">Редактировать</span>
              <span *ngIf="editPhotos && photosCancellable">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editPhotos">
            <div *ngIf="draft.photos && draft.photos.length > 0" class="images-container">
              <div *ngFor="let photo of draft.photos" class="img-box">
                <a href="{{ photo.url }}" [attr.data-lightbox]="'draft-'+draft.id">
                  <img class="img-thumbnail" src="{{ photo.image_sizes.small.path }}"
                       *ngIf="photo.image_sizes && photo.image_sizes.small">
                  <span *ngIf="!photo.image_sizes || !photo.image_sizes.small">[photo]</span>
                </a>
              </div>
            </div>
          </div>
          <div class="panel-row" *ngIf="editPhotos">
            <draft-photo-editor [draft]="draft" [commitCollection]="commitCollection" (onCommitted)="onCommitted()"
                                (onUpdated)="onUpdatedPhotos()"
                                (onCommitError)="onCommitPhotosError($event)"></draft-photo-editor>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body" [class.locked]="isEditLocked() && !editComment">
          <div class="panel-row">
            <div class="pr-title">Коментарий</div>
            <a
              class="edit-link"
              [class.edit-link-editing]="editComment"
              (click)="editComment = switchEditor(editComment)"
            >
              <span *ngIf="!editComment">Редактировать</span>
              <span *ngIf="editComment">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editComment">
            <pre *ngIf="draft.comment && draft.comment != ''">{{ draft.comment }}</pre>
          </div>
          <div class="panel-row" *ngIf="editComment">
            <draft-comment-editor [draft]="draft" (onUpdated)="onUpdated()"></draft-comment-editor>
          </div>
          <div class="mask"></div>
        </div>

        <div class="panel-body notes-panel" *ngIf="draft.id != null && userService.isPrivilegedUser()">
          <div class="panel-row">
            <div class="pr-title">Заметки</div>
          </div>
          <div>
            <form (submit)="onSaveNotes()">
              <div class="form-group">
                <textarea class="notes-area form-control" name="notes" [(ngModel)]="draft.notes" rows="4"></textarea>
              </div>
              <div class="notes-templates">
                <span *ngFor="let template of notesTemplates; let i = index;">
                  <span class="template" [title]="template.title"
                        (click)="onClickNotesTemplate(i)">{{ template.template }}</span><span
                  *ngIf="i < notesTemplates.length - 1"> </span>
                </span>
              </div>
              <button type="submit" class="save-btn" [disabled]="!isNotesUpdated()">Сохранить</button>
            </form>
          </div>
        </div>

        </ng-scrollbar>
      </div>
    </div>
  </div>
</div>

<freighters-fast-list-dialog (savedFreighters)="freighterFiltersEditor.applyFreighters($event)"
                             (savedEmployers)="freighterFiltersEditor.applyEmployers($event)"
                             (savedCrews)="freighterFiltersEditor.applyCrews($event)"
                             #freightersListDialog></freighters-fast-list-dialog>

<legal-entity-selector-dialog #legalEntitySelectorDialog (selected)="routeEditor.onSelectLegalEntity($event)"></legal-entity-selector-dialog>

<delivery-product-dialog (onLinesUpdated)="onUpdateDeliveryProduct($event)"></delivery-product-dialog>

<draft-special-dialog *ngIf="enableChangeSpecial" (selectSpecial)="onSelectDraftSpecial($event)"></draft-special-dialog>

<promo-code-dialog [account]="draft.client" (applied)="onAppliedPromoCode()" *ngIf="userService.isPrivilegedUser()"></promo-code-dialog>


import {switchMap, finalize, throttleTime} from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {OrderDraft} from "../_models/order-draft";
import {DestinationPoint} from "../_models/destination-point";
import {asyncScheduler, Subject, Subscription} from "rxjs";
import {GeoService} from "../_services/geo.service";
import {AlertService} from "../_services/alert.service";
import {LoaderService} from "../_services/loader.service";
import {OrderDraftService, PAGE_SIZE} from "../_services/order-draft.service";
import {Router} from "@angular/router";
import {DeliveryService} from "../_services/delivery.service";
import {Order} from "../_models/order";
import {HttpErrorResponse} from "@angular/common/http";
import {CommitCollection} from "../_committable/commit-collection";
import {DraftPhotoEditorComponent} from "../draft-photo-editor/draft-photo-editor.component";
import {UploadErrorMessageBuilder} from "../_upload/upload-error-message-builder";
import {UserInfoService} from "../_services/user-info.service";
import {WebSocketService} from "../_services/web-socket.service";
import {Message} from "../_websocket/messages/message";
import {TaxiConnection} from "../_websocket/connections/taxi-connection";
import {SearchState} from "../_models/search-state";
import {TaxiSearchState} from "../_websocket/messages/taxi-search-state";
import {VoximplantService} from "../_services/voximplant.service";
import {DraftUtils} from "../_utils/draft-utils";
import {SearchEmployeeStatus} from "../_models/search-employee-status";
import {SearchEmployeeStatusService} from "../_services/search-employee-status.service";
import {CompanyClient} from "../_models/company-client";
import {Account} from "../_models/account";
import {AccountService} from "../_services/account.service";
import {OrderList} from "../_models/order-list";
import {Page} from "../pager/page";
import {CrewsList} from "../_models/search-state/crews-list";
import {OrderService} from "../_services/order.service";
import {FreighterFiltersEditorComponent} from "../freighter-filters-editor/freighter-filters-editor.component";
import {Freighter} from "../_models/freighter";
import {Employer} from "../_models/employer";
import {OrderDraftFreighterFilter} from "../_models/order-draft-freighter-filter";
import {SubsearchState} from "../_models/subsearch-state";
import {Crew} from "../_models/search-state/crew";
import {Crew as OriginalCrew} from "../_models/crew";
import {Route} from "../_models/route";
import {MapService} from "../_services/map.service";
import {Point} from "../_models/point";
import {ExternalSearchExecutionDescriptor} from "../_models/external-search-execution-descriptor";
import {DateTime} from "date-time-js";
import {DraftRouteEditorComponent} from "../draft-route-editor/draft-route-editor.component";
import {Destination} from "../_models/destination";
import {TransportTariff} from "../_models/transport-tariff";
import {LoaderTariff} from "../_models/loader-tariff";
import {AssemblerTariff} from "../_models/assembler-tariff";
import {LiftingTariff} from "../_models/lifting-tariff";
import {SearchStopped} from "../_websocket/messages/search-stopped";
import {SearchPaused} from "../_websocket/messages/search-paused";
import {TrackingService} from "../_models/tracking-service";
import {TrackingServiceService} from "../_services/tracking-service.service";
import {DeliveryProductDialogComponent} from "../delivery-product-dialog/delivery-product-dialog.component";
import {DeliveryProduct} from "../_models/delivery-product";
import {DraftSpecial} from "../_models/draft-special";
import {
  AVAILABLE_DRAFT_TYPES,
  DraftSpecialDialogComponent
} from "../draft-special-dialog/draft-special-dialog.component";
import {TitleService} from "../_services/title.service";
import {OrderDraftCrewFilter} from "../_models/order-draft-crew-filter";
import {OrderDraftEmployerFilter} from "../_models/order-draft-employer-filter";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";
import {ClipboardService} from "../_services/clipboard.service";
import {PayMethodsService} from "../_services/pay-methods.service";
import {AvailablePayMethod} from "../_models/available-pay-method";
import {PromoCodeDialogComponent} from "../promo-code-dialog/promo-code-dialog.component";
import {PromoService} from "../_services/promo.service";
import {PromoCodePresenter} from "../_models/promo-code-presenter";
import {FormErrors} from "../_models/form-errors";

declare var moment: any;

class NotesTemplate {
  constructor(public title: string, public template: string) {}
}


export class DraftOrdersListCommand {
  constructor(private _page?: number) {
  }

  get page(): number {
    return this._page;
  }
}

@Component({
  selector: 'draft-editor',
  templateUrl: './draft-editor.component.html',
  styleUrls: ['./draft-editor.component.css']
})
export class DraftEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() draft: OrderDraft;
  @Input() draftSpecial: DraftSpecial;
  @Input() enableChangeSpecial = false;
  @Output() onSaved = new EventEmitter<OrderDraft>();
  @Output() onLockDraft = new EventEmitter<OrderDraft>();
  @Output() onUnlockDraft = new EventEmitter<OrderDraft>();
  @Output() onDraftChanged = new EventEmitter<OrderDraft>();
  @Output() onDraftChangesCanceled = new EventEmitter<OrderDraft>();
  @ViewChild('linkForEmployerElement') linkForEmployerEl: ElementRef;
  @ViewChild(FreighterFiltersEditorComponent) freighterFiltersEditor: FreighterFiltersEditorComponent;
  @ViewChild(DraftRouteEditorComponent) routeEditor: DraftRouteEditorComponent;
  @ViewChild(DeliveryProductDialogComponent, { static: true }) deliveryProductDialog: DeliveryProductDialogComponent;
  @ViewChild(DraftSpecialDialogComponent) draftSpecialDialog: DraftSpecialDialogComponent;
  @ViewChild(PromoCodeDialogComponent) promoCodeDialog: PromoCodeDialogComponent;

  editCity = false;
  editPay = false;
  editInsurance = false;
  editSchema = false;
  editTariff = false;
  editClient = false;
  editFreighterClient = false;
  editParameters = false;
  editRoute = false;
  editPhotos = false;
  editComment = false;
  editSchedule = false;
  editTransportFeatures = false;
  editIntercomDialog = false;
  editSearchParams = false;
  editLegalEntity = false;
  editFreighterFilters = false;
  lockCounter = 0;
  isTest = false;

  enabledScheduleDelivery = false;
  enabledStartSearch = false;
  enabledRestartSearch = false;
  enabledStartSearchViaLinks = false;
  enabledStop = false;
  enabledPause = false;
  enabledCreateOrder = false;
  enabledClientEdit = false;
  enabledLegalEntityEdit = false;
  enabledFreighterFilters = false;
  enabledOwnTariffs = false;
  enabledTrackNumber = false;
  enabledExternalSearch = false;
  enabledInsurance = false;
  enabledFreighterClient = false;
  enabledDraftLink = false;
  hideControls = false;
  photosCancellable = true;
  enabledEdit = false;
  showCalculation = false;
  showLegalEntityDetails = false;
  showDiscount = false;

  updated = false;
  updatedTransportFeatures = false;
  updatedRoute = false;
  saving = false;
  savedDraft: string;
  order: Order;
  scheduledStart: string;
  searchState: SearchState;
  mainState: SubsearchState;
  foundCrews: Crew[] = [];
  foundCrewsOrder: string;
  nonActiveSubsearches: any = {};
  hasNonActiveSubsearches = false;
  searchDraftId: number;
  orders: Order[] = [];
  draftLink: string;
  draftOrdersList: OrderList = OrderList.empty();
  externalExecutions: ExternalSearchExecutionDescriptor[] = [];
  freighter: Freighter;

  promoCode: PromoCodePresenter;

  private showDestinationClientLegalEntityDetails = new Map<number, boolean>();

  private draftOrdersStream: Subject<DraftOrdersListCommand>;
  private updateStatusesStream: Subject<number[]>;
  private calculationStream: Subject<OrderDraft>;
  private mapClickSubscription: Subscription;

  commitCollection = new CommitCollection();

  notesTemplates: NotesTemplate[] = [
    new NotesTemplate('Во время поиска перемещать тестовую машину в заданную точку', 'point(широта,долгота)'),
    new NotesTemplate('Не снимать с водителя комиссию', 'Без комиссии.'),
    new NotesTemplate('Установить минимальную стоимость заказа', 'Минимальная стоимость - 0.0.'),
    new NotesTemplate('Установить фиксированную стоимость заказа', 'Фиксированная стоимость - 0.0.'),
    new NotesTemplate('Тэг для заказов Авито', '#avito'),
  ];

  private focusedAddr: Subject<DestinationPoint>;
  private committedComponents = 0;
  private editorsOpened = false;
  private taxiConnection: TaxiConnection;
  private searchTimer: any;
  private prevNotes: string = '';
  tierPoint = new Point();
  private trackingServicesMap = new Map<string, TrackingService>();

  public searchEmployeeStatuses: SearchEmployeeStatus[] = [];
  public activeCompanyClient: CompanyClient;
  public foundCrewsList: CrewsList = CrewsList.empty();
  public trackingServices: TrackingService[] = [];
  public selectedTrackingService = '';
  public draftType: any;
  public paymentDistributionSchema?: PaymentDistributionSchema;
  public loadersParameters: string[] = [];

  private activeDraftId: number|null = null;

  constructor(
    private draftService: OrderDraftService,
    private orderService: OrderService,
    private geoService: GeoService,
    private deliveryService: DeliveryService,
    private alertService: AlertService,
    private router: Router,
    private webSocketService: WebSocketService,
    public voximplantService: VoximplantService,
    public userService: UserInfoService,
    private searchEmployeeStatusService: SearchEmployeeStatusService,
    private accountService: AccountService,
    private loaderService: LoaderService,
    private mapService: MapService,
    private payMethodsService: PayMethodsService,
    private trackingServiceService: TrackingServiceService,
    private clipboardService: ClipboardService,
    private titleService: TitleService,
    private promoService: PromoService
  ) {
    this.initDraftOrdersStream();
    this.initUpdateStatusesStream();
    this.initCalculationStream();
  }

  ngOnInit() {
    this.mapClickSubscription = this.mapService.getMapClickObservable().subscribe(p => this.onClickMap(p));
    this.saveDraft();
    this.initTaxiConnection();
    this.initSearchTimer();
    // this.initSearchEmployeeStatuses();
    this.showDestinationClientLegalEntityDetails.clear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['draft']) {
      this.isTest = DraftUtils.isTest(this.draft);

      if(this.userService.isFreighter())
        this.freighter = this.userService.getFreighter();

      this.initControls();
      this.initView();
      this.initMap();
      this.initSearchState();
      this.initActiveCompanyClient();
      this.initLegalEntity();
      this.initTariffs();
      this.initTrackingServices();
      this.initDraftSpecial();
      this.initPaymentDistributionSchema();
      this.initLoadersParameters();
      if(this.draft.id) {
        this.loadAcceptedOrder();
        this.loadOrders();
        this.loadDraftLink();
        this.loadPromoCode();
      }
      this.syncTierPoint();
      this.prevNotes = this.draft.notes;
      this.activeDraftId = this.draft.id;
    }
  }

  private loadPromoCode(): void {
    this.promoCode = null;
    if(!this.userService.isPrivilegedUser())
      return;

    this.promoService
      .listForAccount(this.draft.client, ['entered', 'applied'])
      .subscribe(
        codes => this.promoCode = codes.length > 0 ? codes[0] : null,
        () => {}
      )
    ;
  }

  private syncTierPoint(): void {
    if(this.draft.destinations.length > 0) {
      this.tierPoint.lat = this.draft.destinations[0].destination.lat;
      this.tierPoint.lon = this.draft.destinations[0].destination.lon;
    } else {
      this.tierPoint.lat = 55.75411;
      this.tierPoint.lon = 37.620111;
    }
  }

  private initDraftSpecial(): void {
    this.draftType = (this.draftSpecial && this.draftSpecial.draftTypeIdentifier)
      ? AVAILABLE_DRAFT_TYPES.filter(t => t.identifier === this.draftSpecial.draftTypeIdentifier)[0] || null
      : null;

    if(!this.draft.id)
      this.titleService.changeTitle(`Новая заявка${this.draftType ? ' [' + this.draftType.name + ']' : ''}`);
  }

  private initTrackingServices(): void {
    this.trackingServicesMap.clear();

    this.loaderService.show();
    this.trackingServiceService
      .getAvailableTrackingServices().pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        s => {
          this.trackingServices = s;
          this.selectedTrackingService = (s.length > 0 && !this.userService.isPrivilegedUser()) ? s[0].identifier : '';
          for(let service of s)
            this.trackingServicesMap.set(service.identifier, service);
        },
        () => {}
      )
    ;
  }

  private initTariffs(): void {
    if(!this.draft.tariffs || this.draft.tariffs.length != 1)
      this.draft.tariffs = [new TransportTariff()];
    if(!this.draft.loader_tariffs || this.draft.loader_tariffs.length != 1)
      this.draft.loader_tariffs = [new LoaderTariff()];
    if(!this.draft.assembler_tariffs || this.draft.assembler_tariffs.length != 1)
      this.draft.assembler_tariffs = [new AssemblerTariff()];
    if(!this.draft.lifting_tariffs || this.draft.lifting_tariffs.length != 1)
      this.draft.lifting_tariffs = [new LiftingTariff()];
  }

  private initMap(): void {
    this.mapService.showMap();
    this.mapService.clean();
    this.mapService.displayDraft(this.draft);
    this.initMapLocation();
  }

  private initMapLocation(): void {
    if(this.draft.id === this.activeDraftId)
      return;

    if(!this.draft.destinations || this.draft.destinations.length == 0) {
      this.mapService.setMapPosition(55.753040, 37.622002, 11);
    } else {
      this.mapService.setMapPosition(this.draft.destinations[0].destination.lat, this.draft.destinations[0].destination.lon, 11);
    }
  }

  private initView(): void {
    this.showDiscount = this.draft.fixed_discount != 0 || this.draft.percent_discount != 0;
  }

  private initPaymentDistributionSchema(): void {
    this.paymentDistributionSchema = this.draft.payment_distribution_schemas
      && this.draft.payment_distribution_schemas.length > 0
      && this.draft.payment_distribution_schemas[0].schema || undefined;
  }

  private initLoadersParameters(): void {
    this.loadersParameters = [];
    if(this.draft.loaders) {
      if(this.draft.assembly)
        this.loadersParameters.push('сборка/разборка');
      if(this.draft.rigging)
        this.loadersParameters.push('такелаж');
    }
  }

  private initSearchState(): void {
    if(this.searchDraftId !== this.draft.id || !this.draft.taxi_search || this.draft.taxi_search.status !== 'search') {
      this.searchState = null;
      this.mainState = null;
      this.foundCrews = [];
      this.foundCrewsList = CrewsList.empty();
      this.externalExecutions = [];
      this.searchDraftId = this.draft.id;
      this.mapService.hideSearchState();
      this.mapService.cleanFoundCrews();
    } else {
      if(this.mainState) {
        this.mapService.displaySearchState(this.mainState);
      }
      for(let crew of this.foundCrews)
        this.mapService.addFoundCrew(crew);
    }
  }

  private initDraftOrdersStream(): void {
    this.draftOrdersStream = new Subject<DraftOrdersListCommand>();
    this.draftOrdersStream.pipe(
      throttleTime(5000, asyncScheduler, {
        leading: true, trailing: true
      }),
      switchMap(command => this.draftService.getDraftOrders(this.draft.id, command.page))
    ).subscribe(
      list => this.draftOrdersList = list,
      () => this.initDraftOrdersStream()
    );
  }

  private initUpdateStatusesStream(): void {
    this.updateStatusesStream = new Subject<number[]>();
    this.updateStatusesStream.pipe(
      switchMap(ids => this.orderService.getOrdersStatuses(ids)))
      .subscribe(
        statuses => {
          for(let crew of this.foundCrewsList.crews) {
            for(let orderStatus of statuses) {
              if(crew.order.id === orderStatus.id)
                crew.order.status = orderStatus.status;
            }
          }
        },
        () => this.initUpdateStatusesStream()
      )
  }

  private initCalculationStream(): void {
    this.calculationStream = new Subject<OrderDraft>();
    this.calculationStream.pipe(
      switchMap(draft => {
        this.loaderService.show();
        return this.draftService.requestCalculation(draft, this.draftSpecial).pipe(finalize(() => this.loaderService.hide()));
      }))
      .subscribe(
        calculation => this.applyCalculation(calculation),
        () => this.initCalculationStream()
      );
  }

  private applyCalculation(calculation: any): void {
    if(calculation['price']) {
      let price = calculation['price'];
      this.draft.cost = price['cost'] || 0;
      this.draft.fixed_discount = price['fixed_discount'] || 0;
      this.draft.percent_discount = price['discount'] || 0;
      this.draft.total_cost = price['total_price'] || 0;

      if(price['tariff']) {
        let tariff = price['tariff'];
        this.draft.distance = tariff['distance'];
        this.draft.out_mkad_distance = tariff['after_mkad_distance'];
      }

    }

    this.draft.points = Route.createFromString(calculation['points']).points || [];

    this.draft.calculation = calculation['calculation'] || null;
    this.draft.insurances = this.draft.calculation && this.draft.calculation['insurances'] || [];

    this.mapService.displayDraft(this.draft);
  }

  private initSearchTimer() {
    this.searchTimer = setInterval(() => this.onTickSearchTimer(), 500);
  }

  private initSearchEmployeeStatuses() {
    this.searchEmployeeStatusService
      .getStatuses(this.draft)
      .subscribe(
        statuses => this.searchEmployeeStatuses = statuses,
        r => {}
      )
  }

  private initActiveCompanyClient() {
    this.activeCompanyClient = this.draft.client.company_client;
  }

  private initLegalEntity() {
    if(this.draft.client.id != null)
      this.initLegalEntityByAccount(this.draft.client);
    else if(this.draft.client.phone != null && this.draft.client.phone.trim() !== '')
      this.initLegalEntityByDraftClient();
    else
      this.initLegalEntityByAuthorizedUser();
  }

  private initLegalEntityByAuthorizedUser() {
    this.initLegalEntityByAccount(this.userService.userInfo.account);
  }

  private initLegalEntityByDraftClient() {
    this.accountService
      .getAccountByName(this.draft.client.phone)
      .subscribe(
        a => this.initLegalEntityByAccount(a),
        r => {
          if(r instanceof HttpErrorResponse && r.status === 404) {
            this.alertService.clear();
            this.activeCompanyClient = null;
            this.resetLegalEntity();
          }
        }
      )
  }

  private initLegalEntityByAccount(account: Account) {
    if(this.activeCompanyClient == null || account.company_client == null || this.activeCompanyClient.id !== account.company_client.id) {
      this.activeCompanyClient = account.company_client;
      this.resetLegalEntity();
    }

    this.enabledLegalEntityEdit = this.activeCompanyClient != null;
  }

  private resetLegalEntity() {
    this.draft.legal_entity = null;
  }

  private loadOrders(page: number = 0) {
    if(!this.userService.isPrivilegedUser())
      return;

    if(this.draft.taxi_search && ['search', 'init'].indexOf(this.draft.taxi_search.status) != -1)
      return;

    this.draftOrdersStream.next(new DraftOrdersListCommand(page));
  }

  private loadDraftLink(): void {
    if(!this.userService.isPrivilegedUser())
      return;

    this.draftService
      .getDraftLink(this.draft)
      .subscribe(
        link => this.draftLink = link,
        r => {
          if(r instanceof HttpErrorResponse && r.status === 404) {
            this.alertService.clear();
            this.draftLink = null;
          }
        }
      )
    ;
  }

  private loadAcceptedOrder() {
    this.draftService
      .getAcceptedOrder(this.draft.id)
      .subscribe(
        order => {
          this.order = order;
          this.initControls();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 404) {
            this.alertService.clear();
            this.order = null;
            this.loadAgreedOrder();
          }
        }
      );
  }

  private loadAgreedOrder() {
    this.draftService
      .getAgreedOrder(this.draft.id)
      .subscribe(
        order => {
          this.order = order;
          this.initControls();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 404) {
            this.alertService.clear();
            this.order = null;
            this.loadCompletedOrder()
          }
        }
      );
  }

  private loadCompletedOrder() {
    this.draftService
      .getCompletedOrder(this.draft.id)
      .subscribe(
        order => {
          this.order = order
          this.initControls();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 404) {
            this.alertService.clear();
            this.order = null;
          }
        }
      );
  }

  private loadExternalExecutions(): void {
    this.draftService
      .getExternalSearchExecutions(this.draft)
      .subscribe(
        executions => this.externalExecutions = executions,
        () => {}
      );
  }

  private syncOrdersWithSearchState()
  {
    this.onTickSearchTimer();
    this.refreshFoundCrewsList(this.foundCrewsList.page);
  }

  switchEditor(state: boolean): boolean {
    console.log('switch');

    if(!state) {
      if(this.lockCounter == 0)
        this.onLockDraft.emit(this.draft);

      this.lockCounter ++;
      this.editorsOpened = true;

      return true;
    }

    this.lockCounter --;

    if(this.lockCounter == 0) {
      this.editorsOpened = false;
      this.onUnlockDraft.emit(this.draft);
    }

    return false;
  }

  focusMap(lat: number, lng: number) {
    this.mapService.setMapPosition(lat, lng)

    return false;
  }

  isEditLocked(): boolean {
    return this.lockCounter > 0;
  }

  private initControls() {
    this.enabledStartSearchViaLinks = false;
    this.enabledFreighterFilters = this.draft.id
      && (this.userService.isPrivilegedUser() || this.userService.isFreighter() || this.userService.isDeliveryManager());

    let orderAcceptedStatuses = [ 'accepted', 'completed' ];
    let isOrderAccepted = this.order && orderAcceptedStatuses.indexOf(this.order.status) != -1;

    if(this.draft.delivery) {
      let stopStatuses = ['stopped', 'rejected', 'canceled_by_client', 'success'];
      let isStopped = this.draft.delivery_status == null || stopStatuses.indexOf(this.draft.delivery_status) >= 0;

      this.enabledStartSearch = !isOrderAccepted && isStopped && this.draft.destinations.length > 1 && this.draft.delivery_schema != null;
      this.enabledStop = !isStopped;
      this.enabledPause = false;
    } else {
      let stopStatuses = ['stopped', 'declined', 'not_found', 'error', 'completed'];
      let isStopped = this.draft.taxi_search == null || stopStatuses.indexOf(this.draft.taxi_search.status) >= 0;
      let isPaused = this.draft.taxi_search != null && this.draft.taxi_search.status == 'paused';
      let isAccepted = isOrderAccepted || (this.draft.taxi_search != null && this.draft.taxi_search.status == 'accepted');
      this.enabledStartSearch = this.draft.destinations.length > 1 && !isAccepted && this.draft.id != null && (isStopped || isPaused);
      this.enabledStop = !isStopped && !isAccepted || this.draft.pending_search || this.draft.repeatable_search;
      let statusesToPause = [ 'init', 'search' ];
      this.enabledPause = this.userService.isPrivilegedUser() && this.draft.taxi_search != null && statusesToPause.indexOf(this.draft.taxi_search.status) >= 0;
    }

    this.enabledScheduleDelivery = this.enabledStartSearch && this.draft.delivery_schedule != null;
    this.enabledStartSearchViaLinks = this.enabledStartSearch && this.userService.isPrivilegedUser();

    this.enabledClientEdit = this.userService.isPrivilegedUser();

    this.hideControls = false;
    this.photosCancellable = true;

    let activeStatuses = [ 'init', 'search', 'search_via_links', 'completed', 'accepted' ];
    this.enabledEdit = isOrderAccepted ? false : (this.draft.taxi_search == null || activeStatuses.indexOf(this.draft.taxi_search.status) == -1);

    this.enabledRestartSearch = this.draft.taxi_search && this.draft.taxi_search.status === 'search';

    this.enabledOwnTariffs = this.userService.isAvailableOwnTariffs();

    this.enabledTrackNumber = this.userService.isPrivilegedUser();

    this.enabledExternalSearch = this.userService.isPrivilegedUser();

    this.enabledInsurance = this.userService.isPrivilegedUser();

    this.enabledFreighterClient = this.userService.isFreighter();

    this.enabledDraftLink = this.userService.isPrivilegedUser();

    this.initScheduledStart();

    this.enabledCreateOrder = this.draft.id
      && this.draft.destinations.length > 0
      && this.draft.crew_filters
      && this.draft.crew_filters.length == 1
      && this.draft.employer_filters.length == 0
      && !isOrderAccepted
      && !this.enabledStop;
  }

  private initScheduledStart() {
    this.scheduledStart = null;

    if(this.draft.destinations.length < 2)
      return;

    let firstDestination = this.draft.destinations[0];
    if(firstDestination.arrival_time == null)
      return;

    let arrivalTime = new Date(firstDestination.arrival_time);
    let curTime = new Date();

    if(curTime.getTime() < arrivalTime.getTime())
      this.scheduledStart = firstDestination.arrival_time;
  }

  private switchOffEditors() {

    this.editCity = false;
    this.editPay = false;
    this.editInsurance = false;
    this.editSchema = false;
    this.editTariff = false;
    this.editClient = false;
    this.editFreighterClient = false;
    this.editParameters = false;
    this.editRoute = false;
    this.editComment = false;
    this.editSchedule = false;
    this.editTransportFeatures = false;
    this.editSearchParams = false;
    this.editLegalEntity = false;
    this.editFreighterFilters = false;

    this.lockCounter = 0;

    if(this.photosCancellable)
      this.editPhotos = false;
    else
      this.lockCounter ++;

    if(this.lockCounter == 0)
      this.onUnlockDraft.emit(this.draft);

    this.editorsOpened = false;
  }

  private hasOpenedEditor() {
    return this.editorsOpened;
  }

  private saveDraft() {
    this.savedDraft = JSON.stringify(this.draft);
  }

  private restoreDraft() {
    this.draft = JSON.parse(this.savedDraft) as OrderDraft;
    this.mapService.displayDraft(this.draft);
  }

  private validateDraft(): boolean {
    this.alertService.clear();

    if(this.hasOpenedEditor()) {
      this.alertService.warning('Сохраните данные или отмените редактирование');
      return false;
    }

    if(this.draft.client.phone && !this.draft.client.phone.match(/^7[0-9]{10}$/)) {
      this.alertService.warning('Номер телефона указан неверно');
      return false;
    }

    return true;
  }

  private addDraft() {
    this.loaderService.show();
    this.saving = true;
    this.draftService
      .addDraft(this.draft, this.selectedTrackingService, this.draftSpecial).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.saving = false;
      }))
      .subscribe(
        r => {
          this.alertService.success('Заявка добавлена');
          this.router.navigate([`/drafts/${r.body.id}`]);
        },
        () => {}
      );
  }

  private updateDraft() {
    this.loaderService.show();
    this.saving = true;
    this.draftService
      .updateDraft(this.draft).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.saving = false;
      }))
      .subscribe(
        () => {
          this.updated = false;
          this.updatedRoute = false;
          this.saveOrCommit();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 409) {
            this.alertService.warning('Кто-то параллельно внёс изменения в заявку. Перезагрузите заявку и внесите изменения повторно.');
          }
        }
      );
  }

  private updateForcedData(): void {
    this.updateFreighterFilters();
    this.updateSearchParams();
  }

  private updateFreighterFilters(): void {
    this.loaderService.show();
    this.draftService
      .updateFreighterFilters(this.draft).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.updated = false;
          this.saveOrCommit();
        },
        () => {}
      );
  }

  private updateSearchParams(): void {
    this.loaderService.show();
    this.draftService
      .updateSearchParams(this.draft).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.updated = false;
          this.saveOrCommit();
        },
        () => {}
      );
  }

  private scheduleDelivery() {
    this.hideControls = true;
    this.loaderService.show();
    this.draftService
      .scheduleDelivery(this.draft).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.onSaved.emit(this.draft);
        },
        () => this.hideControls = false
      );
  }

  private startSearch() {
    this.initSearchState();
    this.hideControls = true;
    this.loaderService.show();
    this.draftService
      .startSearch(this.draft).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.onSaved.emit(this.draft);
        },
        () => this.hideControls = false
      );
  }

  private scheduleTaxiSearch() {
    this.initSearchState();
    this.hideControls = true;
    this.loaderService.show();
    this.draftService
      .scheduleTaxiSearch(this.draft).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.onSaved.emit(this.draft);
        },
        () => this.hideControls = false
      );
  }

  private restartSearch() {
    this.initSearchState();
    this.hideControls = true;
    this.loaderService.show();
    this.draftService
      .restartSearch(this.draft).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.onSaved.emit(this.draft);
        },
        () => this.hideControls = false
      );
  }

  private startSearchViaLinks() {
    this.hideControls = true;
    this.loaderService.show();
    this.draftService
      .startSearchViaLinks(this.draft).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.onSaved.emit(this.draft);
        },
        () => this.hideControls = false
      );
  }

  private stop() {
    this.hideControls = true;
    this.loaderService.show();

    if(this.draft.delivery) {
      this.deliveryService
        .updateDeliveryStatus(this.draft, 'stopped').pipe(
        finalize(() => this.loaderService.hide()))
        .subscribe(
          () => {
            this.onSaved.emit(this.draft);
          },
          () => this.hideControls = false
        );
    } else {
      this.draftService
        .stopSearch(this.draft).pipe(
        finalize(() => this.loaderService.hide()))
        .subscribe(
          () => {
            this.onSaved.emit(this.draft);
          },
          () => this.hideControls = false
        );
    }
  }

  private pauseSearch() {
    this.hideControls = true;
    this.loaderService.show();

    this.draftService
      .pauseSearch(this.draft).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.onSaved.emit(this.draft);
        },
        () => this.hideControls = false
      );
  }

  private initTaxiConnection() {
    this.taxiConnection = this.webSocketService.createTaxiConnection();
    this.taxiConnection.start();
    this.taxiConnection.message.subscribe(m =>
      this.onTaxiMessage(m)
    );
  }

  private saveOrCommit()
  {
    if(this.hasSubcomponentsToCommit())
      this.commitSubcomponentChanges();
    else
      this.onDraftSaved();
  }

  private commitSubcomponentChanges() {
    this.committedComponents = this.commitCollection.countCommittables();

    this.commitCollection.commit();
  }

  private hasSubcomponentsToCommit() {
    return this.commitCollection.hasCommittables();
  }

  isNotesUpdated(): boolean {
    let prevNotes = this.prevNotes || '';
    let notes = this.draft.notes || '';

    if(prevNotes == '' && notes == '')
      return false;

    if(prevNotes == '' || notes == '')
      return true;

    return this.prevNotes != this.draft.notes;
  }

  private refreshFoundCrewsList(page: number = 0): void {
    this.foundCrews = [];
    if(this.searchState == null) {
      this.mapService.hideSearchState();
      this.mapService.cleanFoundCrews();
      return;
    }

    for(let substate of this.searchState.states)
      this.foundCrews = this.foundCrews.concat(substate.found);

    this.foundCrews = this.reorderFoundCrews(this.foundCrews);

    if(this.foundCrews.length == 0) {
      console.log('Clean up crews list');
      this.foundCrewsList = CrewsList.empty();
      this.mapService.cleanFoundCrews();
      return;
    }

    for (const crew of this.foundCrews)
      this.mapService.addFoundCrew(crew);

    let start = page * PAGE_SIZE;
    let end = start + PAGE_SIZE;
    if(end >= this.foundCrews.length)
      end = this.foundCrews.length;

    if(end <= start) {
      this.refreshFoundCrewsList();
      return;
    }

    let crewsPage = this.foundCrews.slice(start, end);
    for(let updatedCrew of crewsPage) {
      for(let prevCrew of this.foundCrewsList.crews) {
        if(updatedCrew.order.id === prevCrew.order.id)
          updatedCrew.order.status = prevCrew.order.status;
      }
    }

    this.foundCrewsList = new CrewsList(page, PAGE_SIZE, this.foundCrews.length, crewsPage);
    this.updateStatusesOfFoundCrewsPage();
  }

  private reorderFoundCrews(crews: Crew[]): Crew[] {
    if(this.foundCrewsOrder == 'foundAt') {
      return crews.sort((a, b) => {
        const arriveDiff = b.found_at - a.found_at;
        return arriveDiff == 0 ? a.order.id - b.order.id : arriveDiff;
      });
    } else {
      return crews.sort((a, b) => {
        const arriveDiff = a.arrival_time - b.arrival_time;
        return arriveDiff == 0 ? a.order.id - b.order.id : arriveDiff;
      });
    }
  }

  private updateStatusesOfFoundCrewsPage(): void {
    if(this.foundCrewsList.crews.length == 0)
      return;

    let ordersIds = this.foundCrewsList.crews.map(c => c.order.id);
    this.updateStatusesStream.next(ordersIds);
  }

  private selectMainSubsearchState(state: SearchState): SubsearchState {
    for(let substate of state.states) {
      if(substate.search_type == 'internal')
        return substate;
    }

    return state.states[0];
  }

  private recalculate(): void {
    this.calculationStream.next(this.draft);
  }

  private createDraftLink(): void {
    this.draftService
      .createDraftLink(this.draft)
      .subscribe(
        link => this.draftLink = link,
        () => {}
      )
    ;
  }

  private syncPaymentMethodWithDeliverySchema(): void {
    if(!this.draft.delivery_schema) {
      this.onUpdated();
      return;
    }

    if(this.userService.isPrivilegedUser()) {
      this.payMethodsService
        .getAvailablePayMethodsForUserAndDeliverySchema(this.draft.client.id, this.draft.delivery_schema.id)
        .subscribe(
          methods => this.fixPayMethodWithAvailableMethods(methods),
          () => {}
        );
    } else {
      this.payMethodsService
        .getMyAvailablePayMethodsForDeliverySchema(this.draft.delivery_schema.id)
        .subscribe(
          methods => this.fixPayMethodWithAvailableMethods(methods),
          () => {}
        )
    }
  }

  private fixPayMethodWithAvailableMethods(availablePayMethods: AvailablePayMethod[]): void {
    if(availablePayMethods.length == 0) {
      this.draft.pay_method = null;
      this.draft.pay_method_option = null;
    } else if(!this.isPayMethodValid(availablePayMethods)) {
      let availablePayMethod = availablePayMethods[0];
      this.draft.pay_method = availablePayMethod.method;
      this.draft.pay_method_option = availablePayMethod.option;
    }
    this.onUpdated();
  }

  private isPayMethodValid(availableMethods: AvailablePayMethod[]): boolean {
    for(const availableMethod of availableMethods) {
      if(this.draft.pay_method == availableMethod.method && this.draft.pay_method_option == availableMethod.option)
        return true;
    }
    return false;
  }

  getFoundAt(crew: Crew): string {
    return moment().to(crew.found_at * 1000);
  }

  onUpdated() {
    this.switchOffEditors();
    this.initPaymentDistributionSchema();
    this.initLoadersParameters();
    this.updated = true;
    this.onDraftChanged.emit(this.draft);
    this.recalculate();
    this.syncTierPoint();
  }

  onRouteUpdated(): void {
    this.updatedRoute = true;

    this.onUpdated();
  }

  onDeliverySchemaUpdated() {
    this.syncPaymentMethodWithDeliverySchema();
  }

  onUpdatedPhotos() {
    this.photosCancellable = false;
    this.switchOffEditors();
    this.updated = true;
  }

  onUpdatedTransportFeatures() {
    this.updatedTransportFeatures = true;
    this.onUpdated();
  }

  onUpdatedClient() {
    this.onUpdated();
    this.initLegalEntity();
  }

  onUpdatedFreighterClient() {
    console.log(this.draft.freighter_client);

    this.onUpdated();
  }

  onFocusAddrField(focusedAddr: Subject<DestinationPoint>) {
    this.focusedAddr = focusedAddr;
  }

  onClickMap(point: Point) {
    if (!this.focusedAddr)
      return;

    this.geoService
      .getLocation(point.lat, point.lng)
      .subscribe(
        a => {
          if (this.focusedAddr) {
            let point = new DestinationPoint();
            point.lon = a.longitude;
            point.lat = a.latitude;
            point.addr = a.formated_address;

            this.focusedAddr.next(point);
          }
        },
        e => console.log(e)
      )
  }

  onSave() {
    if(!this.validateDraft())
      return;

    if(this.draft.id) {
      if(this.enabledEdit)
        this.updateDraft();
      else
        this.updateForcedData();
    } else {
      this.addDraft();
    }
  }

  onCancel() {
    this.photosCancellable = true;

    this.restoreDraft();
    this.switchOffEditors();
    this.updated = false;
    this.updatedRoute = false;

    this.onDraftChangesCanceled.emit(this.draft);
  }

  onScheduleDelivery() {
    this.scheduleDelivery();
  }

  onStop() {
    this.stop();
  }

  onPauseSearch() {
    this.pauseSearch();
  }

  onStartSearch() {
    if(!this.validateDraft())
      return;

    this.startSearch();
  }

  onScheduleTaxiSearch() {
    if(!this.validateDraft())
      return;

    this.scheduleTaxiSearch();
  }

  onStartSearchViaLinks() {
    if(!this.validateDraft())
      return;

    this.startSearchViaLinks();
  }

  onCommitted() {
    this.committedComponents --;

    console.log(`committed, wait yet ${this.committedComponents} components`);

    if(this.committedComponents <= 0)
      this.onDraftSaved();
  }

  onCommitPhotosError(component: DraftPhotoEditorComponent) {
    console.log(component.getUploadErrors());

    let errors = ['Ошибка загрузки фото:'].concat(component.getUploadErrors().map(UploadErrorMessageBuilder.build));
    this.alertService.error(errors);
  }

  private onDraftSaved() {
    this.alertService.success('Заявка сохранена');
    this.initControls();
    this.switchOffEditors();
    if(this.draft.id)
      this.onSaved.emit(this.draft);
  }

  private onTaxiMessage(message: Message) {
    if(this.draft.id && message instanceof TaxiSearchState && message.getDraftId() == this.draft.id) {
      this.searchState = message.state;
      this.mainState = this.selectMainSubsearchState(this.searchState);
      this.mapService.displaySearchState(this.mainState);

      if(['search', 'init'].indexOf(this.searchState.status) != -1) {
        this.syncOrdersWithSearchState();
        if(this.userService.isPrivilegedUser())
          this.loadExternalExecutions();
      } else {
        this.loadOrders(this.draftOrdersList.page);
      }

      this.nonActiveSubsearches = this.searchState.substatuses;
      for(let subsearch of this.searchState.states) {
        if(this.nonActiveSubsearches[subsearch.search_type])
          delete this.nonActiveSubsearches[subsearch.search_type];
      }
      this.hasNonActiveSubsearches = Object.keys(this.nonActiveSubsearches).length > 0;
    }

    if(this.draft.id && (message instanceof SearchStopped || message instanceof SearchPaused) && message.getDraftId() == this.draft.id) {
      this.onSaved.emit(this.draft);
    }
  }

  externalSearchExecutionTime(descriptor: ExternalSearchExecutionDescriptor): string {
    let executionTime = moment(descriptor.execution_time);
    if(executionTime.diff(moment()) < 0)
      return 'запускается';

    return moment().to(descriptor.execution_time);
  }

  externalSearchStartedAt(filter: OrderDraftFreighterFilter): string|null {
    if(!filter.applied_at)
      return null;

    return 'поиск запущен ' + new DateTime(new Date(filter.applied_at)).format('d.MM.yyyy г. в HH:mm:ss');
  }

  private onTickSearchTimer() {
    if(this.draft.taxi_search != null && this.searchState != null) {
      let startTime = new Date(this.draft.taxi_search.started_at);
      let now = new Date();
      this.searchState.timer = now.getTime() - startTime.getTime();
    }
  }

  isVisibleClientLegalEntityDetailsInDestination(destination: Destination): boolean {
    return this.showDestinationClientLegalEntityDetails.get(destination.id) || false;
  }

  private showClientLegalEntityDetailsInDestination(destination: Destination): void {
    this.showDestinationClientLegalEntityDetails.set(destination.id, true);
  }

  trackingServiceIdentifierToName(trackingServiceIdentifier: string): string {
    return this.trackingServicesMap.has(trackingServiceIdentifier)
      ? this.trackingServicesMap.get(trackingServiceIdentifier).name
      : trackingServiceIdentifier;
  }

  private showDeliveryProductDialog(deliveryProduct: DeliveryProduct): void {
    this.deliveryProductDialog.showDialog(deliveryProduct);
  }

  createOrder(): void {
    this.loaderService.show();
    this.orderService
      .createOrderByDraft(this.draft).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        orderId => this.router.navigate(['/orders', this.draft.crew_filters[0].crew.freighter.id, orderId]),
        () => {}
      )
    ;
  }

  onSaveNotes() {
    this.loaderService.show();
    this.draftService
      .updateNotes(this.draft.id, this.draft.notes).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.prevNotes = this.draft.notes,
        () => {}
      )
  }

  onSaveIntercomDialogLink() {
    this.loaderService.show();
    this.draftService
      .updateIntercomDialog(this.draft.id, this.draft.intercom_dialog).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.editIntercomDialog = false,
        () => {}
      )
  }

  onImported() {
    this.onDraftSaved();
    this.syncTierPoint();
  }

  onClickNotesTemplate(i: number): void {
    if(this.draft.notes == null)
      this.draft.notes = '';

    if(this.draft.notes != '')
      this.draft.notes += "\n";

    this.draft.notes += this.notesTemplates[i].template;
  }

  onChangeOrdersPage(page: Page): void {
    this.loadOrders(page.num);
  }

  onChangeFoundCrewsPage(page: Page): void {
    this.refreshFoundCrewsList(page.num);
  }

  onSaveFreighterFilters(f: [Freighter[], Employer[], OriginalCrew[], boolean]): void {
    let [ freighters, employers, crews, autoAssigment ] = f;
    this.draft.freighter_filters = freighters.map(f => {
      let filter = new OrderDraftFreighterFilter();
      filter.freighter = f;
      return filter;
    });
    this.draft.employer_filters = employers.map(e => {
      let filter = new OrderDraftEmployerFilter();
      filter.employer = e;
      return filter;
    });
    this.draft.crew_filters = crews.map(c => {
      let filter = new OrderDraftCrewFilter();
      filter.crew = c;
      return filter;
    });
    this.draft.auto_assigment_crew = autoAssigment;
    this.onUpdated();
  }

  onRestart(): void {
    this.restartSearch();
  }

  onChangeOrderOfFoundCrews(): void {
    this.foundCrewsOrder = this.foundCrewsOrder == 'foundAt' ? '' : 'foundAt';
    this.refreshFoundCrewsList();
  }

  onShowClientLegalEntityDetailsInDestination(destination: Destination): void {
    this.showClientLegalEntityDetailsInDestination(destination);
  }

  onCreateDraftLink(): void {
    this.createDraftLink();
  }

  onCopyDraftLink(): void {
    this.clipboardService.saveToClipboard(this.draftLink, 'Ссылка на заявку скопирована в буфер обмена');
  }

  onCopyTrackNumber(trackNumber: string): void {
    this.clipboardService.saveToClipboard(trackNumber, `Трек-номер ${trackNumber} скопирован в буфер обмена`);
  }

  onShowDeliveryProductDialog(deliveryProduct: DeliveryProduct): void {
    this.showDeliveryProductDialog(deliveryProduct);
  }

  onUpdateDeliveryProduct(deliveryProduct: DeliveryProduct): void {
    this.routeEditor.onUpdateDeliveryProduct(deliveryProduct);
  }

  ngOnDestroy(): void {
    this.taxiConnection.close();
    this.stopSearchTimer();

    this.mapService.clean();
    this.mapService.hideMap();
    this.mapClickSubscription.unsubscribe();

    this.activeDraftId = null;
  }

  private stopSearchTimer() {
    if(this.searchTimer != null) {
      clearInterval(this.searchTimer);
      this.searchTimer = null;
    }
  }

  onSelectDraftSpecial(special: DraftSpecial) {
    this.draftSpecial = special;
    this.initDraftSpecial();
    this.recalculate();
  }

  onShowDraftSpecialDialog(): void {
    this.draftSpecialDialog.showDialog();
  }

  onCreateOrder(): void {
    if(confirm('Подтверждаете создание заказа?'))
      this.createOrder();
  }

  onOpenPromoCodeDialog(): void {
    this.promoCodeDialog.showDialog(this.promoCode && this.promoCode.code);
  }

  onAppliedPromoCode(): void {
    this.onUpdated();
  }
}

import {filter, finalize} from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {OptimizationTask} from "../_models/optimization-task";
import {OptimizationTaskService} from "../_services/optimization-task.service";
import {LoaderService} from "../_services/loader.service";
import {NavigationEnd, Router} from "@angular/router";
import {ComplexDeliveryForm} from "../complex-delivery-form/complex-delivery-form";
import {Storehouse} from "../_models/storehouse";
import {StorehouseService} from "../_services/storehouse.service";
import {StorehouseCreatorComponent} from "../storehouse-creator/storehouse-creator.component";
import {DateTimeslot} from "../date-timeslot/date-timeslot";
import {DateTimeslotComponent} from "../date-timeslot/date-timeslot.component";
import {OptimizationTaskAutoBuildTransportsRequest} from "../_models/optimization-task-auto-build-transports-request";
import {UserInfoService} from "../_services/user-info.service";
import {ExpressDeliveryTimeSlotService} from "../_services/express-delivery-time-slot.service";
import {TimeSlot} from "../_models/time-slot";

@Component({
  selector: 'edit-complex-delivery',
  templateUrl: './edit-complex-delivery.component.html',
  styleUrls: ['./edit-complex-delivery.component.css']
})
export class EditComplexDeliveryComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() optimizationTask: OptimizationTask;
  @Input() destinationId: number;
  @Output() saveEvent = new EventEmitter<void>();
  @Output() onSaved = new EventEmitter<void>();
  @Output() registerFormListener = new EventEmitter<ComplexDeliveryForm>();
  @ViewChild(StorehouseCreatorComponent) storehouseCreator: StorehouseCreatorComponent;
  @ViewChild(DateTimeslotComponent) desiredArrivalComponent: DateTimeslotComponent;
  @ViewChild(DateTimeslotComponent) deliveryDateTimeComponent: DateTimeslotComponent;
  @ViewChild('editComplexDelivery', { static: true }) dialogEl: ElementRef;

  mode = 'edit';

  private form: ComplexDeliveryForm;
  private wasShown = false;
  private modalWasInit = false;

  storehouseId: number;
  storehouses: Storehouse[] = [];
  desiredArrival = new DateTimeslot();
  deliveryDateTime = new DateTimeslot();
  expressDeliveryTimeSlots: TimeSlot[] = [];
  enabledDesiredArrival = false;
  enabledDeliveryDateTime = false;
  isRouteOptimizationEnabled: boolean;
  isRouteOptimizationAvailable: boolean;
  isExpressMode = false;
  editable = false;
  executed = false;

  constructor(
    private optimizationTaskService: OptimizationTaskService,
    private storehouseService: StorehouseService,
    private expressDeliveryTimeSlotService: ExpressDeliveryTimeSlotService,
    private userService: UserInfoService,
    private loaderService: LoaderService,
    private router: Router
  ) { }

  ngAfterViewInit(): void {
    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      }).on('show.bs.modal', () => {
        this.wasShown = true;
      });
    }
  }

  ngOnInit() {
    this.isRouteOptimizationAvailable = this.userService.isAvailableRouteOptimization();

    if(this.registerFormListener != null)
      this.registerFormListener.subscribe(f => this.onRegisterForm(f));

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        if(this.wasShown)
          this.hideForm();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changed task');
    this.validate();

    if(changes['optimizationTask'] && this.optimizationTask && this.optimizationTask.city) {
      this.initStorehouses();
      this.initExpressMode();
      this.executed = this.optimizationTask.status == 'executed';
      this.editable = !this.executed && !this.optimizationTask.imported_from;
    }

    this.initDesiredArrival();
  }

  private initStorehouses(): void {
    this.storehouses = [];

    this.storehouseService
      .getAvailableStorehouses(this.optimizationTask.city)
      .subscribe(
        s => {
          this.storehouses = s;
          console.log(this.optimizationTask);
          if(this.optimizationTask)
            this.storehouseId = this.optimizationTask.storehouse_id;
        },
        () => {}
      )
    ;
  }

  private initDesiredArrival(): void {
    this.enabledDesiredArrival = !this.isRouteOptimizationAvailable && this.optimizationTask && this.optimizationTask.status !== 'executed' && !this.optimizationTask.imported_from
      && (!this.optimizationTask.transports || this.optimizationTask.transports.length === 0);
  }

  private initExpressMode(): void {
    this.isExpressMode = this.optimizationTask.delivery_schema?.is_express || false;
    this.initExpressDeliveryTimeSlots();
  }

  initExpressDeliveryTimeSlots(): void {
    if(this.isExpressMode) {
      this.expressDeliveryTimeSlotService
        .getTimeSlots()
        .subscribe(s => {
          this.expressDeliveryTimeSlots = s;
          this.initDeliveryDateTime();
        })
      ;
    }
  }

  initDeliveryDateTime(): void {
    this.enabledDeliveryDateTime = !this.isRouteOptimizationAvailable && this.optimizationTask && this.optimizationTask.status !== 'executed' && !this.optimizationTask.imported_from
      && (!this.optimizationTask.transports || this.optimizationTask.transports.length === 0);

    if(!this.enabledDeliveryDateTime)
      return;

    this.deliveryDateTime.date = new Date(this.optimizationTask.date);

    if(this.optimizationTask.destinations.length > 1) {
      let firstDestination = this.optimizationTask.destinations[1];
      console.log(firstDestination);
      this.deliveryDateTime.timeBegin = new Date(firstDestination.time_slot_begin);
      this.deliveryDateTime.timeEnd = new Date(firstDestination.time_slot_end);
    }
  }

  private save() {
    if(this.storehouseId > 0) {
      this.rawSave();
    } else {
      this.storehouseCreator.save(storehouse => {
        this.storehouses.push(storehouse);
        this.storehouseId = storehouse.id;
        this.rawSave();
      });
    }
  }

  private rawSave(): void {
    this.applyDataToTask();

    this.form.submit();
  }

  private applyDataToTask(): void {
    this.optimizationTask.storehouse_id = this.storehouseId;

    // Здесь важен порядок вызова saveEvent и applyDeliveryTimeSlot.
    // При вызове saveEvent внутренние значения редакторов записываются в destination-ы задачи.
    // Затем вызывается applyDeliveryTimeSlot, который при необходимости переписывает значения тайм-слотов на глобальное
    // значение, указанное в дате и времени доставки клиенту.

    this.saveEvent.emit();

    if(this.isExpressMode)
      this.applyDeliveryTimeSlot();
  }

  private applyDeliveryTimeSlot(): void {
    let startTime = this.deliveryDateTime.timeBegin;
    let endTime = this.deliveryDateTime.timeEnd;

    if(!startTime || !endTime)
      return;

    let timeSlotBeginStr = startTime.toString();
    let timeSlotEndStr = endTime.toString();

    for(let destination of this.optimizationTask.destinations) {
      if(!destination.stock) {
        destination.time_slot_begin = timeSlotBeginStr;
        destination.time_slot_end = timeSlotEndStr;
      }
    }
  }

  private autoBuildTransports(): void {
    this.loaderService.show();
    this.loaderService.showText('Подготовка рейсов...');
    this.optimizationTaskService
      .autoBuildTaskTransports(this.optimizationTask, this.buildAutoBuildTransportsRequest())
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: () => {
          this.desiredArrivalComponent.removeSavedForTask(this.optimizationTask);
          if(this.userService.isAvailableRouteOptimization())
            this.onSaved.emit();
          else
            this.executeTask();
        },
        error: () => {
          this.desiredArrivalComponent?.saveForTask(this.optimizationTask);
        }
      })
    ;
  }

  private buildAutoBuildTransportsRequest(): OptimizationTaskAutoBuildTransportsRequest {
    let request = new OptimizationTaskAutoBuildTransportsRequest();

    request.isRouteOptimizationEnabled = this.isRouteOptimizationEnabled;
    this.setupArrivalDataToRequest(request);

    return request;
  }

  private setupArrivalDataToRequest(request: OptimizationTaskAutoBuildTransportsRequest): void {
    request.desiredArrivalDate = this.desiredArrival.getFormattedDate();
    request.desiredArrivalTimeBegin = this.desiredArrival.getFormattedTimeBegin();
    request.desiredArrivalTimeEnd = this.desiredArrival.getFormattedTimeEnd();
  }

  private executeTask(): void {
    this.loaderService.show();
    this.loaderService.showText('Запуск подбора...');
    this.optimizationTaskService
      .executeTask(this.optimizationTask)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: () => {
          this.onSaved.emit();
        },
        error: () => {}
      })
    ;
  }

  private createCopy() {
    this.saveEvent.emit();
    this.loaderService.show();
    this.optimizationTaskService
      .addTask(this.optimizationTask).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        id => {
          this.hideForm();
          this.router.navigate([ `/complex-deliveries/${id}` ]);
        },
        () => {}
      );
  }

  private validate(): boolean {
    return this.form && this.form.validate();
  }

  private hideForm() {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  onRegisterForm(form: ComplexDeliveryForm) {
    this.form = form;

    console.log('Form was registered');
  }

  onAccept() {
    if(this.validate())
      this.save();
    else
      alert("Не все поля заполнены правильно. Исправьте их и повторите попытку.");
  }

  onCreateCopy() {
    if(!confirm('Подтверждаете создание копии?'))
      return;

    if(this.validate())
      this.createCopy();
    else
      alert("Не все поля заполнены правильно. Исправьте их и повторите попытку.");
  }

  onChangeMode() {
    this.mode = this.mode == 'edit' ? 'import' : 'edit';
  }

  onFormSaved() {
    this.desiredArrivalComponent?.removeSavedForTask(this.optimizationTask);

    if(this.userService.isAvailableRouteOptimization()) {
      this.onSaved.emit();
      return;
    }

    this.loaderService.show();
    this.loaderService.showText('Проверка загруженных адресов...');
    this.optimizationTaskService
      .checkTaskForAutoBuildTransports(this.optimizationTask)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe(allowed => {
        if(allowed)
          this.autoBuildTransports();
        else
          this.onSaved.emit();
      });
  }

  onChangeDesiredArrival(): void {
    this.desiredArrivalComponent?.saveForTask(this.optimizationTask);
  }

  onChangeDeliveryDateTime(): void {
    if(!this.wasShown)
      return;


  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideForm();
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderDraft} from "../_models/order-draft";

@Component({
  selector: 'draft-parameters-editor',
  templateUrl: './draft-parameters-editor.component.html',
  styleUrls: ['./draft-parameters-editor.component.css']
})
export class DraftParametersEditorComponent implements OnInit {
  @Input() draft: OrderDraft;
  @Output() onUpdated = new EventEmitter<OrderDraft>();

  loaders: number;
  assembly: boolean;
  rigging: boolean;

  constructor() { }

  ngOnInit() {
    this.loaders = this.draft.loaders;
    this.assembly = this.draft.assembly;
    this.rigging = this.draft.rigging;
  }

  onSave() {
    this.draft.loaders = this.loaders;
    this.draft.assembly = this.assembly;
    this.draft.rigging = this.rigging;

    this.onUpdated.emit(this.draft);
  }
}

<ng-template #riggingFactor>
  <span *ngIf="calculation['with_rigging']">
    * rm
    * rf
  </span>
</ng-template>
<ng-template #calculatedRigging let-value="value">
  <span *ngIf="calculation['with_rigging']">
    {{ (value * calculation.rigging_multiplier * calculation.tariff_factor_with_rigging)|formatCost }}
  </span>
  <span *ngIf="!calculation['with_rigging']">
    {{ value|formatCost }}
  </span>
</ng-template>

<ng-template [ngIf]="transportMode">
  <tr>
    <th>Время в пути</th>
    <td>{{ calculation.time_at_way }} мин.</td>
  </tr>
  <tr>
    <th>Оплачиваемое время в пути</th>
    <td>
      ({{ calculation.time_at_way }} мин. - {{ tariff.includedMileageDuration }} бесплатных мин.)
      * {{ calculation.time_at_way_minute_cost }}&nbsp;р.
      <ng-container *ngTemplateOutlet="riggingFactor"></ng-container>
      = <ng-container *ngTemplateOutlet="calculatedRigging; context: { value: calculation.time_at_way_cost }"></ng-container>
    </td>
  </tr>
  <ng-template [ngIf]="calculation.distance_in_base_area > 0">
    <tr class="header">
      <th colspan="2">Внутри зоны "{{ calculation.base_area_name }}"</th>
    </tr>
    <tr>
      <th>Общее расстояние</th>
      <td>{{ calculation.distance_in_base_area }} км</td>
    </tr>
    <tr>
      <th>Оплачиваемое расстояние</th>
      <td>{{ calculation.distance_in_base_area }} км - {{ tariff.freeDestinationInBaseArea }} бесплатных км = {{ calculation.payable_distance_in_base_area }} км</td>
    </tr>
    <tr>
      <th>Стоимость</th>
      <td>
        {{ calculation.payable_distance_in_base_area }} км * {{ tariff.kmCostInBaseArea }} р.
        <ng-container *ngTemplateOutlet="riggingFactor"></ng-container>
        = <ng-container *ngTemplateOutlet="calculatedRigging; context: { value: calculation.distance_in_base_area_cost }"></ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template [ngIf]="calculation.distance_beyond_base_area > 0">
    <tr class="header">
      <th colspan="2">За пределами зоны "{{ calculation.base_area_name }}"</th>
    </tr>
    <tr>
      <th>Общее расстояние</th>
      <td>{{ calculation.distance_beyond_base_area }} км</td>
    </tr>
    <tr>
      <th>Стоимость</th>
      <td>
        {{ calculation.distance_beyond_base_area }} км * {{ tariff.kmCostBeyondBaseArea }} р.
        <ng-container *ngTemplateOutlet="riggingFactor"></ng-container>
        = <ng-container *ngTemplateOutlet="calculatedRigging; context: { value: calculation.distance_beyond_base_area_cost }"></ng-container>
    </tr>
  </ng-template>
  <ng-template [ngIf]="calculation.additional_destinations_cost > 0">
    <tr class="header">
      <th colspan="2">Дополнительные точки</th>
    </tr>
    <tr>
      <th>Количество</th>
      <td>{{ calculation.additional_destinations_count }}</td>
    </tr>
    <tr>
      <th>Стоимость</th>
      <td>
        {{ calculation.additional_destinations_count }} * {{ tariff.additionalDestinationCost }} р.
        <ng-container *ngTemplateOutlet="riggingFactor"></ng-container>
        = <ng-container *ngTemplateOutlet="calculatedRigging; context: { value: calculation.additional_destinations_cost }"></ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template ngFor let-add [ngForOf]="transportAdditional">
    <tr class="header">
      <th colspan="2">Дополнительно на адресе "{{ add.destination && add.destination.destination.addr }}"</th>
    </tr>
    <tr *ngIf="add.timeCost">
      <th>Дополнительное ожидание</th>
      <td>
        {{ add.duration }} мин. * {{ add.minuteCost }} р.
        <ng-container *ngTemplateOutlet="riggingFactor"></ng-container>
        = <ng-container *ngTemplateOutlet="calculatedRigging; context: { value: add.timeCost }"></ng-container>
      </td>
    </tr>
  </ng-template>
</ng-template>
<ng-template [ngIf]="!transportMode && !totalMode">
  <ng-template ngFor let-add [ngForOf]="loadersAdditional">
    <tr class="header">
      <th colspan="2">Дополнительно на адресе {{ add.destinationNum + 1 }} ({{ add.destination && add.destination.destination.addr }})</th>
    </tr>
    <tr *ngFor="let item of add.items || []">
      <th>Груз {{ item.weight }} кг</th>
      <td>{{ item.count }} шт. * {{ item.price }} р. = {{ item.cost }} р.</td>
    </tr>
    <tr *ngIf="add.additionalItemsCost">
      <th>
        Итоговая стоимость
        <span *ngIf="add.loading"> погрузки</span>
        <span *ngIf="add.unloading"> разгрузки</span>
      </th>
      <td>
        {{ add.additionalItemsCost }}
        <ng-container *ngTemplateOutlet="riggingFactor"></ng-container>
        <span *ngIf="calculation['with_rigging']">
          = <ng-container *ngTemplateOutlet="calculatedRigging; context: { value: add.additionalItemsCost }"></ng-container>
        </span>
      </td>
    </tr>
  </ng-template>
</ng-template>
<ng-template [ngIf]="totalMode">
  <ng-template [ngIf]="calculation['with_rigging']">
    <tr class="header variables-description">
      <th colspan="2"><sup>*</sup>переменные, используемые в формулах</th>
    </tr>
    <tr class="variables-description">
      <th>rm = {{ calculation.rigging_multiplier }}</th>
      <td>множитель тарифа (используется, когда количество грузчиков в заказе превышает максимальное их количество, описанное в тарифах)</td>
    </tr>
    <tr class="variables-description">
      <th>rf = {{ calculation.tariff_factor_with_rigging }}</th>
      <td>коэффициент, на который умножается стоимость, если используется такелаж</td>
    </tr>
  </ng-template>
</ng-template>
<div>
  <table class="table table-condensed table-striped">
    <thead>
    <tr *ngIf="product.code">
      <th colspan="6">Заказ №{{ product.code }}</th>
    </tr>
    <tr *ngIf="product.name && product.name != '' && (!product.lines || product.lines.length == 0)">
      <th colspan="6">
        <table class="table table-condensed table-striped shipment">
          <thead>
          <tr>
            <th>Наименование</th>
            <th>Количество</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ product.name }}</td>
            <td>{{ product.count || 1 }}</td>
          </tr>
          </tbody>
        </table>
      </th>
    </tr>
    <tr *ngIf="product.lines && product.lines.length > 0">
      <th>Название</th>
      <th>Артикул</th>
      <th class="align-center">Количество</th>
      <th class="align-center">Сумма</th>
      <th class="align-center">Вес, кг</th>
      <th class="align-right">ДхШхВ, см</th>
    </tr>
    </thead>
    <tbody *ngIf="product.lines && product.lines.length > 0">
    <tr *ngFor="let line of product.lines">
      <td>{{ line.name }}</td>
      <td>{{ line.code }}</td>
      <td class="align-center">{{ line.count }}</td>
      <td class="align-center">{{ line.cost }}</td>
      <td class="align-center">{{ line.weight }}</td>
      <td class="align-right text-nowrap">
        <span *ngIf="line.length || line.width || line.height">{{ line.length }} x {{ line.width }} x {{ line.height }}</span>
      </td>
    </tr>
    <tr *ngIf="product.discount > 0">
      <td>Скидка</td>
      <td colspan="5" class="align-right">-{{ product.discount }}</td>
    </tr>
    </tbody>
    <tfoot *ngIf="hasShipmentWeight || hasShipmentSize">
    <tr>
      <td colspan="6">
        <table class="table table-condensed table-striped shipment">
          <thead>
          <tr>
            <th></th>
            <th class="align-center">Вес, кг</th>
            <th class="align-right">ДхШхВ, см</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>Отгрузка</th>
            <td class="align-center">{{ product.weight }}</td>
            <td class="align-right">{{ product.length }} Х {{ product.width }} Х {{ product.height }}</td>
          </tr>
          </tbody>
        </table>
      </td>
    </tr>
    </tfoot>
    <tfoot>
    <tr>
      <th colspan="6">
        <div *ngIf="product.cost">Сумма: {{ product.cost }}</div>
        <div *ngIf="product.pay_method || payMethodEdit">
          Способ оплаты:
          <span *ngIf="!enabledEdit">{{ product.pay_method | payMethod }}</span>
          <span class="pay-method" *ngIf="!payMethodEdit && enabledEdit" (click)="onSwitchPayMethodEdit()" title="Изменить">{{ product.pay_method | payMethod }}</span>
          <span class="pay-method-select" *ngIf="payMethodEdit">
            <select [(ngModel)]="payMethod" (ngModelChange)="onChangePayMethod()" [name]="'pay_method_' + product.id">
              <option value="">оплачено</option>
              <option value="card">карта</option>
              <option value="cash">наличные</option>
            </select>
          </span>
          <span *ngIf="!payMethodEdit && payMethod == 'card' && product.external_pay_link">[внешняя ссылка]</span>
          <span *ngIf="payMethodEdit && payMethod == 'card'">
            <label>[<input type="checkbox" [name]="'external_pay_link_' + product.id" [(ngModel)]="product.external_pay_link" (ngModelChange)="onChangePayMethod()"> внешняя ссылка]</label>
          </span>
          (<span *ngIf="product.paid">оплачено</span><span *ngIf="!product.paid">не оплачено</span><ng-template [ngIf]="product.payment_link && payMethod == 'card' && !product.paid && !product.external_pay_link">
          <span class="glyphicon glyphicon-duplicate copy-link" title="скопировать платёжную ссылку" (click)="onCopyPaymentLink()"></span>
        </ng-template>)
        </div>
        <div *ngIf="!product.pay_method && !enabledEdit">Оплачено</div>
        <div *ngIf="!product.pay_method && enabledEdit && !payMethodEdit">
          <span class="pay-method" (click)="onSwitchPayMethodEdit()" title="Изменить">Оплачено</span>
        </div>
      </th>
    </tr>
    </tfoot>
  </table>
</div>
<div *ngIf="product.tracks" class="tracks">
  <delivery-track [track]="track" small *ngFor="let track of product.tracks"></delivery-track>
</div>


import {finalize, switchMap, throttleTime} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OrderService} from "../_services/order.service";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";
import {Order} from "../_models/order";
import {asyncScheduler, Subject, Subscription} from "rxjs";
import {DestinationPoint} from "../_models/destination-point";
import {GeoService} from "../_services/geo.service";
import {WebSocketService} from "../_services/web-socket.service";
import {OrderScreenConnection} from "../_websocket/connections/order-screen-connection";
import {Message} from "../_websocket/messages/message";
import {CarFeed} from "../_websocket/messages/car-feed";
import {MapTransport} from "../_models/map-transport";
import {UpFeed} from "../_websocket/messages/up-feed";
import {OrderConnection} from "../_websocket/connections/order-connection";
import {OrderChanged} from "../_websocket/messages/order-changed";
import {UserInfoService} from "../_services/user-info.service";
import {NotificationService} from "../_services/notification.service";
import {VoximplantService} from "../_services/voximplant.service";
import {OrderUtils} from "../_utils/order-utils";
import {OrderDraftService} from "../_services/order-draft.service";
import {OrderReport} from "../_models/order-report";
import {NewIssueComponent} from "../new-issue/new-issue.component";
import {Destination} from "../_models/destination";
import {DestinationDeliveryIssuesComponent} from "../destination-delivery-issues/destination-delivery-issues.component";
import {OrderDeliveryIssuesComponent} from "../order-delivery-issues/order-delivery-issues.component";
import {DestinationDeliveryHistoryComponent} from "../destination-delivery-history/destination-delivery-history.component";
import {OrderDeliveryHistoryComponent} from "../order-delivery-history/order-delivery-history.component";
import {TitleService} from "../_services/title.service";
import {AccountSumHistoryRow} from "../_models/account-sum-history-row";
import {EXTERNAL_ORDER_STATUSES} from "../_maps/external-order-statuses";
import {MapService} from "../_services/map.service";
import {Point} from "../_models/point";
import {RouteEditorComponent} from "../route-editor/route-editor.component";
import {FreightersFastListDialogComponent} from "../freighters-fast-list-dialog/freighters-fast-list-dialog.component";
import {Employer} from "../_models/employer";
import {EmployeeService} from "../_services/employer.service";
import {HttpErrorResponse} from "@angular/common/http";
import {CrewsMapService} from "../_services/crews-map.service";
import {ScreenUtils} from "../_utils/screen/screen-utils";
import {Freighter} from "../_models/freighter";
import {PassportDialogComponent} from "../passport-dialog/passport-dialog.component";
import {TrackingService} from "../_models/tracking-service";
import {TrackingServiceService} from "../_services/tracking-service.service";
import {DeliveryProduct} from "../_models/delivery-product";
import {DeliveryProductDialogComponent} from "../delivery-product-dialog/delivery-product-dialog.component";
import {TransportTariff} from "../_models/transport-tariff";
import {LoaderTariff} from "../_models/loader-tariff";
import {AssemblerTariff} from "../_models/assembler-tariff";
import {LiftingTariff} from "../_models/lifting-tariff";
import {FreighterClient} from "../_models/freighter-client";
import {CrewService} from "../_services/crew.service";
import {Crew} from "../_models/crew";
import {ClipboardService} from "../_services/clipboard.service";
import {AdjustmentCost} from "../_models/adjustment-cost";
import {ROUTE_TIME_MARKERS_STEP} from "../map/map.component";
import {EVENT_STATUS} from "../calendar/calendar.colors";
import {MapUtils} from "../_utils/map-utils";

class NotesTemplate {
  constructor(public title: string, public template: string) {}
}

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  @ViewChild(NewIssueComponent) newIssueDialog: NewIssueComponent;
  @ViewChild(OrderDeliveryIssuesComponent) orderIssuesDialog: OrderDeliveryIssuesComponent;
  @ViewChild(DestinationDeliveryIssuesComponent) destinationDeliveryIssuesDialog: DestinationDeliveryIssuesComponent;
  @ViewChild(DestinationDeliveryHistoryComponent) destinationDeliveryHistoryDialog: DestinationDeliveryHistoryComponent;
  @ViewChild(OrderDeliveryHistoryComponent) orderDeliveryHistoryDialog: OrderDeliveryHistoryComponent;
  @ViewChild(RouteEditorComponent) routeEditor: RouteEditorComponent;
  @ViewChild(FreightersFastListDialogComponent) freighterListDialog: FreightersFastListDialogComponent;
  @ViewChild(PassportDialogComponent) passportDialog: PassportDialogComponent;
  @ViewChild(DeliveryProductDialogComponent, { static: true }) deliveryProductDialog: DeliveryProductDialogComponent;

  order: Order;
  orderReport: OrderReport;
  cars: MapTransport[] = [];
  isTest = false;
  visitedDestination = 0;
  nextDestination = 0;

  editPeriod = false;
  editPayment = false;
  editInsurance = false;
  editRoute = false;
  editCrew = false;
  editPhotoComment = false;
  editTariff = false;
  editOrderParameters = false;
  editOrderStatus = false;
  editClientIntercomDialog = false;
  editDriverIntercomDialog = false;
  editFreighterClient = false;
  expandBriefPanel = false;
  showCostAdjustments = false;
  showCalculation = false;
  showCostHistory = false;
  showLegalEntityDetails = false;
  showAccountSumHistory = false;
  showEmployerPassport = false;
  showTariffs = false;

  enabledDeliveryIssues = false;
  enabledDeliveryHistory = false;
  enabledExecutorSumHistory = false;
  enabledExternalStatuses = false;
  enabledClient = false;
  enabledFreighterClient = false;
  enabledInsurance = false;
  enabledCostAdjustments = false;
  enabledCreateIssue = false;

  externalStatus: string;
  externalOrderStatus: string;
  externalOrderStatusDescription: string;
  externalStatusesExpanded = false;

  showTrack = false;
  enabledTrackVisibilityControl = false;

  clientSumHistory: AccountSumHistoryRow[] = null;
  executorSumHistory: AccountSumHistoryRow[] = null;
  executorCompanySumHistory: AccountSumHistoryRow[] = null;

  loadersParameters: string[] = [];

  costAdjustments: AdjustmentCost[] = [];

  tariff: TransportTariff;
  loaderTariff: LoaderTariff = null;
  assemblerTariff: AssemblerTariff = null;
  liftingTariff: LiftingTariff = null;

  notesTemplates: NotesTemplate[] = [
    new NotesTemplate('Не снимать с водителя комиссию', 'Без комиссии.'),
    new NotesTemplate('Установить минимальную стоимость заказа', 'Минимальная стоимость - 0.0.'),
    new NotesTemplate('Установить фиксированную стоимость заказа', 'Фиксированная стоимость - 0.0.'),
    new NotesTemplate('Тэг для заказов Авито', '#avito'),
  ];

  private orderId: number;
  private freighterId: number;
  private focusedAddr: Subject<DestinationPoint>;
  private savedOrder: string;
  private updatedPhotos = false;
  private orderScreenConnection: OrderScreenConnection;
  private orderConnection: OrderConnection;
  private requireUpdate = false;
  private orderStream = new Subject<[number, number]>();
  private requiredInitOrder = true;
  orderGroup: string;
  private prevNotes: string = '';
  private showDestinationClientLegalEntityDetails = new Map<number, boolean>();
  private mapClickSubscription: Subscription;
  private selectCrewSubscription: Subscription;
  private trackingServicesMap = new Map<string, TrackingService>();

  isCrewsOnMap = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private orderService: OrderService,
              private employerService: EmployeeService,
              private crewService: CrewService,
              private draftService: OrderDraftService,
              private alertService: AlertService,
              private loaderService: LoaderService,
              private geoService: GeoService,
              private webSocketService: WebSocketService,
              public userService: UserInfoService,
              public voximplantService: VoximplantService,
              private _notificationService: NotificationService,
              private titleService: TitleService,
              private mapService: MapService,
              private crewsMapService: CrewsMapService,
              private trackingServiceService: TrackingServiceService,
              private clipboardService: ClipboardService
  ) {}

  ngOnInit() {
    this.initOrderStream();
    this.initTrackingServices();
    this.mapClickSubscription = this.mapService.getMapClickObservable().subscribe(p => this.onClickMap(p));
    this.requiredInitOrder = true;
    this.route.params
      .subscribe(
        params => {
          this.orderId = parseInt(params['id']);
          this.freighterId = parseInt(params['freighterId']);
          this.openOrder();
          this.loadOrder(this.freighterId, this.orderId);
        },
        e => console.error(e)
      );
  }

  private openOrder(): void {
    this.orderScreenConnection = this.webSocketService.createScreenOrderConnection(this.orderId);
    this.orderConnection = this.webSocketService.createOrderConnection(this.orderId);
  }

  initMapLocation(): void {
    let period = this.order.periods[0];

    if (period.destinations.length > 0) {
      this.mapService.setMapPosition(period.destinations[0].destination.lat, period.destinations[0].destination.lon);
    }
  }

  private initIssues(): void {
    this.enabledDeliveryIssues = this.userService.isDeliveryManager() || this.userService.isPrivilegedUser();
    this.enabledCreateIssue = this.enabledDeliveryIssues;
  }

  private initOrderStream() {
    this.orderStream = new Subject<[number, number]>();
    this.orderStream.pipe(
      throttleTime(2500, asyncScheduler, {
        leading: true, trailing: true
      }),
      switchMap(id => {
        this.loaderService.show();
        this.updatedPhotos = false;
        this.requireUpdate = false;
        return this.orderService.getOrder(id[0], id[1]).pipe(finalize(() => this.loaderService.hide()))
      })
    ).subscribe({
      next: order => {
        this.titleService.changeTitle(`#${order.draft.id} (${order.id}) - Заказ`);
        this.order = order;
        this.savedOrder = JSON.stringify(order);
        if (this.requiredInitOrder) {
          this.initOrder();
        } else {
          this.reconnect();
          this.initExternalStatuses();
          this.initOrderGroup();
          this.mapService.displayOrder(order);
        }

        this.initReport();
        this.initShowTrackControls();
        this.initDestinationIndicators();
        this.initLoadersParameters();
        this.initCostAdjustments();
        this.initIssues();
      },
      error: r => {
        if (r.status === 404)
          this.alertService.error('Заказ не найден');
        else
          this.initOrderStream();
      }
    });
  }

  private initTrackingServices(): void {
    this.trackingServicesMap.clear();

    this.loaderService.show();
    this.trackingServiceService
      .getAvailableTrackingServices().pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        s => {
          for(let service of s)
            this.trackingServicesMap.set(service.identifier, service);
        },
        () => {}
      )
    ;
  }

  protected reloadOrder() {
    this.loadOrder(this.order.freighter.id, this.order.id);
  }

  protected loadOrder(freighterId: number, id: number) {
    this.orderStream.next([freighterId, id]);
  }

  protected initOrder() {
    this.mapService.showMap();
    this.mapService.setMapPosition(55.753040, 37.622002, 11);
    this.mapService.displayOrder(this.order);

    this.initConnections();
    this.initMapLocation();
    this.initOrderGroup();
    this.initTariffs();

    this.prevNotes = this.order.notes;
    this.requiredInitOrder = false;
    this.isTest = OrderUtils.isTest(this.order);

    this.enabledDeliveryHistory = !this.userService.isFreighter();

    this.clientSumHistory = null;
    this.executorSumHistory = null;
    this.enabledExecutorSumHistory = this.userService.isPrivilegedUser();

    if(this.showAccountSumHistory)
      this.loadSumHistory();

    this.initExternalStatuses();

    let employer = this.order.periods[0].employer;
    this.showEmployerPassport = employer && Employer.hasPassport(employer);

    this.initClient();

    this.enabledInsurance = !this.userService.isFreighter();
  }

  private initTariffs(): void {
    let period = this.order.periods[0];
    this.tariff = period.tariff;
    this.loaderTariff = period.loader_tariff;
    this.assemblerTariff = period.assembler_tariff;
    this.liftingTariff = period.lifting_tariff;

    this.showTariffs = !!(this.tariff || this.loaderTariff || this.assemblerTariff || this.liftingTariff);
  }

  private initClient(): void {
    this.enabledClient = !this.userService.isFreighter();
    this.enabledFreighterClient = this.userService.isFreighter();
  }

  private initShowTrackControls(): void {
    this.enabledTrackVisibilityControl = this.order.status == 'accepted';
  }

  private initExternalStatuses(): void {
    let period = this.order.periods[0];

    this.externalOrderStatus = period.external_order_status;
    this.externalOrderStatusDescription = this.externalOrderStatus && EXTERNAL_ORDER_STATUSES[this.externalOrderStatus];

    if(!period.external_history || period.external_history.length == 0) {
      this.enabledExternalStatuses = false;
      return;
    }

    this.externalStatus = period.external_history[period.external_history.length - 1].description;
    this.enabledExternalStatuses = true;
  }

  private initOrderGroup(): void {
    for (let period of this.order.periods) {
      this.orderGroup = this.getOrderStatusGroup(this.order);
    }
  }

  protected initConnections() {
    this.initOrderScreenConnection();
    this.initOrderConnection();
  }

  protected initDestinationIndicators() {
    this.visitedDestination = -1;
    this.nextDestination = -1;
    for (let status of this.order.periods[0].history) {
      if(status.execution_status == 'moved_to_order')
        this.nextDestination = 0;

      if(status.execution_status == 'on_start_place' || status.execution_status == 'on_end_place') {
        this.visitedDestination = this.nextDestination = this.visitedDestination + 1;
      } else if(status.execution_status == 'moving') {
        this.nextDestination = this.visitedDestination + 1;
      }
    }
  }

  private initLoadersParameters(): void {
    this.loadersParameters = [];
    let period = this.order.periods[0];
    if(period.loaders) {
      if(period.assembly)
        this.loadersParameters.push('сборка/разборка');
      if(period.rigging)
        this.loadersParameters.push('такелаж');
    }
  }

  private initCostAdjustments(): void {
    this.enabledCostAdjustments = false;
    this.costAdjustments = [];

    this.orderService
      .getOrderCostAdjustments(this.order)
      .subscribe(
        adjustments => {
          this.costAdjustments = adjustments;
          this.enabledCostAdjustments = adjustments.length > 0;
        },
        () => {}
      )
  }

  protected initReport() {
    if(this.userService.isPrivilegedUser()) {
      this.orderService
        .getOrderReport(this.order.id)
        .subscribe(
          report => this.orderReport = report,
          () => {
          }
        );
    } else if(this.userService.isFreighter()) {
      this.orderService
        .getOrderReportForFreighter(this.userService.getFreighter().id, this.order.id)
        .subscribe(
          report => this.orderReport = report,
          () => {
          }
        );
    }
  }

  protected initOrderScreenConnection() {
    this.orderScreenConnection.start();
    this.orderScreenConnection.message.subscribe(m => this.onOrderScreenConnectionMessage(m));
  }

  protected initOrderConnection() {
    this.orderConnection.start();
    this.orderConnection.message.subscribe(m => this.onOrderConnectionMessage(m));
  }

  protected reconnect() {
    this.orderScreenConnection.reconnect();
  }

  focusMap(lat: number, lng: number) {
    this.mapService.setMapPosition(lat, lng);

    return false;
  }

  switchState(state: boolean): boolean {
    return !state;
  }

  switchEditor(state: boolean): boolean {
    if (!state)
      return true;

    if (this.updatedPhotos) {
      this.reloadOrder();
    } else if (!this.hasAnyEditing() && this.requireUpdate) {
      this.reloadOrder();
    } else {
      this.order = JSON.parse(this.savedOrder) as Order;
      this.mapService.displayOrder(this.order);
    }

    return false;
  }

  private loadSumHistory(): void {
    this.loadClientSumHistory();
    if(this.enabledExecutorSumHistory) {
      this.loadExecutorSumHistory();
      this.loadExecutorCompanySumHistory();
    }
  }

  private loadClientSumHistory(): void {
    this.loaderService.show();

    let sumHistory = this.userService.isCustomer()
      ? this.orderService.getOrderClientSumHistory(this.order)
      : this.orderService.getOrderParticipantSumHistory(this.order, this.order.draft.client);

    sumHistory.pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        history => this.clientSumHistory = history,
        () => {}
      )
    ;
  }

  private loadExecutorSumHistory(): void {
    let executors = this.order.periods.filter(p => p.status === 'finished').map(p => p.employer.account);
    if(executors.length == 0) {
      this.executorSumHistory = [];
      return;
    }

    this.loaderService.show();
    this.orderService
      .getOrderFreighterSumHistory(this.order).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        history => this.executorSumHistory = history,
        () => {}
      )
    ;
  }

  private loadExecutorCompanySumHistory(): void {
    this.loaderService.show();
    this.orderService
      .getOrderParticipantSumHistory(this.order, this.order.freighter.account).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        history => this.executorCompanySumHistory = history,
        () => {}
      )
    ;
  }

  private updateCars(cars: MapTransport[]) {
    for (let updatedCar of cars) {
      let car = this.findCar(updatedCar.feedTransport.transport.id);
      if (car === null)
        continue;

      car.feedTransport.point = updatedCar.feedTransport.point;
      car.feedTransport.azimuth = updatedCar.feedTransport.azimuth;
      car.marker = updatedCar.marker;
      car.path.points = car.path.points.concat(updatedCar.path.points);
      car.path.mapPoints = car.path.mapPoints.concat(MapUtils.points2Map(updatedCar.path.points));
      car.path.updateMilestonesFromLastPoints(ROUTE_TIME_MARKERS_STEP, updatedCar.path.points.length);
    }
  }

  private getOrderStatusGroup(order: Order) {
    for (var key in EVENT_STATUS) {
      if (EVENT_STATUS[key].filter(item => order.status == item).length > 0) {
        return key
      }
    }
  }

  private findCar(transportId: number): MapTransport {
    for (let car of this.cars) {
      if (car.feedTransport.transport.id === transportId)
        return car;
    }
    return null;
  }

  private hasAnyEditing() {
    return this.editPeriod
      || this.editPayment
      || this.editInsurance
      || this.editRoute
      || this.editCrew
      || this.editPhotoComment
      || this.editTariff
      || this.editOrderParameters
      || this.editOrderStatus;
  }

  private closeOrder(): void {
    this.hideCrews();

    this.orderScreenConnection.close();
    this.orderConnection.close();

    this.mapService.clean();
    this.mapService.hideMap();
    this.mapClickSubscription.unsubscribe();

    this.requiredInitOrder = true;
  }

  onUpdated() {
    this.editPeriod = false;
    this.editPayment = false;
    this.editInsurance = false;
    this.editRoute = false;
    this.editCrew = false;
    this.editPhotoComment = false;
    this.editTariff = false;
    this.editOrderParameters = false;
    this.editOrderStatus = false;
    this.editFreighterClient = false;
    this.expandBriefPanel == true ? this.expandBriefPanel = true : this.expandBriefPanel = false;

    this.reloadOrder();
  }

  onClickMap(point: Point) {
    console.log('click map', point);

    this.geoService
      .getLocation(point.lat, point.lng)
      .subscribe(
        a => {
          if (this.focusedAddr) {
            let point = new DestinationPoint();
            point.lon = a.longitude;
            point.lat = a.latitude;
            point.addr = a.formated_address;

            this.focusedAddr.next(point);
          }
        },
        e => console.log(e)
      )
  }

  onFocusAddrField(focusedAddr: Subject<DestinationPoint>) {
    this.focusedAddr = focusedAddr;
  }

  onUpdatePhoto() {
    this.updatedPhotos = true;
  }

  onOrderScreenConnectionMessage(message: Message) {
    if(this.isCrewsOnMap)
      return;

    if (message instanceof UpFeed) {
      let updatedCars = message.transports.map(t => MapTransport.createFromFeedTransport(t));
      this.updateCars(updatedCars);
    } else if (message instanceof CarFeed) {
      this.cars = message.transports.map(t =>{
        let mapTransport = MapTransport.createFromFeedTransport(t);
        mapTransport.path.prepareMilestones(ROUTE_TIME_MARKERS_STEP);
        mapTransport.ignoreDriverStatus = true;
        return mapTransport;
      });
      this.mapService.displayTransports(this.cars);
      console.log(this.cars);
    }
  }

  onOrderConnectionMessage(message: Message) {
    console.log('ORDER WS MESSAGE ->', message);
    if (message instanceof OrderChanged && message.orderId === this.order.id) {
      if (this.hasAnyEditing())
        this.requireUpdate = true;
      else
        this.reloadOrder();
    }
  }

  ngOnDestroy(): void {
    this.closeOrder();
  }

  expandPanel() {

    this.expandBriefPanel = this.switchState(this.expandBriefPanel);
  }

  private updateStatus(status: string) {
    console.log(`Updating status to '${status}'...`);
    this.loaderService.show();
    this.orderService
      .updateOrderStatus(this.order, status).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.order.status = status;
        },
        () => {}
      )
    ;
  }

  private updateFreighterClient(client: FreighterClient): void {
    this.loaderService.show();
    this.orderService
      .updateOrderFreighterClient(this.order, client).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        clientId => {
          client.id = clientId
          this.order.draft.freighter_client = client;
          this.onUpdated();
        },
        () => {}
      )
    ;
  }

  acceptDriverOrder() {
    this.updateStatus('accepted');
  }

  declineDriverOrder() {
    this.updateStatus('declined');
  }

  canBeEdited() {
    if (this.userService.isCustomer()) {
      return false;
    }

    return ['complete', 'canceled'].indexOf(this.getOrderStatusGroup(this.order)) === -1;
  }

  canBeEditedTariff() {
    if (!this.canBeEdited())
      return false;

    return this.order.draft.taxi_schema == null || this.order.draft.taxi_schema == 'first';
  }

  canBeInsured(): boolean {
    if (!this.canBeEdited())
      return false;

    return this.order.insurances.length == 0;
  }

  canBeCanceledInsuring(): boolean {
    if (!this.canBeEdited())
      return false;

    return this.order.insurances.length > 0;
  }

  isNotesUpdated(): boolean {
    let prevNotes = this.prevNotes || '';
    let notes = this.order.notes || '';

    if(prevNotes == '' && notes == '')
      return false;

    if(prevNotes == '' || notes == '')
      return true;

    return this.prevNotes != this.order.notes;
  }

  isVisibleClientLegalEntityDetailsInDestination(destination: Destination): boolean {
    return this.showDestinationClientLegalEntityDetails.get(destination.id) || false;
  }

  private showClientLegalEntityDetailsInDestination(destination: Destination): void {
    this.showDestinationClientLegalEntityDetails.set(destination.id, true);
  }

  private changeOrderEmployer(employer: Employer): void {
    this.loaderService.show();
    this.employerService
      .getEmployeeCrew(employer).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        crew => {
          this.closeOrder();
          this.loaderService.show();
          this.orderService
            .updatePeriodCrew(this.order, this.order.periods[0], crew).pipe(
            finalize(() => this.loaderService.hide()))
            .subscribe(
              () => {
                if(employer.freighter.id == this.freighterId) {
                  this.openOrder();
                  this.loadOrder(this.freighterId, this.order.id);
                } else {
                  this.router.navigate(['/orders', employer.freighter.id, this.order.id]);
                }
              },
              () => {
                this.openOrder();
              }
            );
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 404) {
            this.alertService.warning("Чтобы выбрать водителя, добавьте его в экипаж.")
          }
        }
      );
  }

  private changeOrderCrew(crew: Crew): void {
    this.closeOrder();
    this.loaderService.show();
    this.orderService
      .updatePeriodCrew(this.order, this.order.periods[0], crew).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          if(crew.freighter.id == this.freighterId) {
            this.openOrder();
            this.loadOrder(this.freighterId, this.order.id);
          } else {
            this.router.navigate(['/orders', crew.freighter.id, this.order.id]);
          }
        },
        () => {
          this.openOrder();
        }
      );
  }

  private showCrews(): void {
    this.hideCrews();

    this.isCrewsOnMap = true;
    this.crewsMapService.showCrewsOnMap(ScreenUtils.buildTransportParams(this.order.draft));
    this.selectCrewSubscription = this.crewsMapService.getSelectCrewObservable().subscribe(c => this.onSelectCrew(c));
  }

  private hideCrews(): void {
    if(this.isCrewsOnMap) {
      this.crewsMapService.removeCrewsFromMap();
      this.selectCrewSubscription.unsubscribe();
      this.isCrewsOnMap = false;
    }
  }

  trackingServiceIdentifierToName(trackingServiceIdentifier: string): string {
    return this.trackingServicesMap.has(trackingServiceIdentifier)
      ? this.trackingServicesMap.get(trackingServiceIdentifier).name
      : trackingServiceIdentifier;
  }

  private cancelInsurance(): void {
    this.loaderService.show();
    this.orderService
      .cancelInsurance(this.order).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success("Страховка отменена");
          this.reloadOrder();
        },
        () => {}
      )
    ;
  }

  private showDeliveryProductDialog(deliveryProduct: DeliveryProduct): void {
    this.deliveryProductDialog.showDialog(deliveryProduct);
  }

  private showFreighterSelectDialog(): void {
    let period = this.order.periods[0];

    this.loaderService.show();
    this.crewService
      .findCrew(this.order.freighter, period.employer, period.transport).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        crew => {
          this.freighterListDialog.showDialog([], [], [crew])
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 404) {
            this.alertService.clear();
            this.freighterListDialog.showDialog();
          }
        }
      )
    ;
  }

  private generateDriverAuthLink() {
    this.loaderService.show();
    this.orderService
      .generateDriverAuthLink(this.order, this.order.periods[0])
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: () => {
          this.alertService.success('Ссылка на водительское web-приложение сгенерирована');
          this.reloadOrder();
        },
        error: () => {}
      })
  }

  private onSelectCrew(car: MapTransport): void {
    if(this.order.periods[0].employer.id != car.feedTransport.employer.id) {
      let freighter = new Freighter();
      freighter.id = car.feedTransport.freighter_id;

      let employer = new Employer();
      employer.id = car.feedTransport.employer.id;
      employer.freighter = freighter;

      this.changeOrderEmployer(employer);
    }
  }

  onSaveNotes() {
    this.loaderService.show();
    this.orderService
      .updateOrderNotes(this.order, this.order.notes).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.prevNotes = this.order.notes,
        () => {}
      )
  }

  onSaveClientIntercomDialogLink() {
    this.loaderService.show();
    this.draftService
      .updateIntercomDialog(this.order.draft.id, this.order.draft.intercom_dialog).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.editClientIntercomDialog = false,
        () => {}
      )
  }

  onSaveDriverIntercomDialogLink() {
    this.loaderService.show();
    this.orderService
      .updateDriverIntercomDialog(this.order, this.order.driver_intercom_dialog).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.editDriverIntercomDialog = false,
        () => {}
      )
  }

  onClickNotesTemplate(i: number): void {
    if(this.order.notes == null)
      this.order.notes = '';

    if(this.order.notes != '')
      this.order.notes += "\n";

    this.order.notes += this.notesTemplates[i].template;
  }

  onShowNewIssueDialog(destination: Destination) {
    this.newIssueDialog.show(destination, this.order);
  }

  onCreatedIssue() {
    this.reloadOrder();
  }

  onShowOrderIssues() {
    this.orderIssuesDialog.show(this.order);
  }

  onShowOrderDeliveryHistory() {
    this.orderDeliveryHistoryDialog.show(this.order);
  }

  onShowDestinationDeliveryIssues(destination: Destination) {
    this.destinationDeliveryIssuesDialog.show(destination, this.enabledCreateIssue);
  }

  onShowDestinationDeliveryHistory(destination: Destination) {
    this.destinationDeliveryHistoryDialog.show(destination);
  }

  onSwitchShowAccountSumHistory(): void {
    this.showAccountSumHistory = !this.showAccountSumHistory;
    if(this.showAccountSumHistory)
      this.loadSumHistory();
  }

  onSwitchTrackShow(): void {
    this.showTrack = !this.showTrack;
    if(this.showTrack)
      this.mapService.showTrack();
    else
      this.mapService.hideTrack();
  }

  onShowClientLegalEntityDetailsInDestination(destination: Destination): void {
    this.showClientLegalEntityDetailsInDestination(destination);
  }

  onChangeEmployer(employers: Employer[]): void {
    let employer = employers[0];
    if(employer.id != this.order.periods[0].employer.id)
      this.changeOrderEmployer(employer);
  }

  onChangeCrew(crews: Crew[]): void {
    if(crews.length == 0)
      return;

    let crew = crews[0];
    let period = this.order.periods[0];
    if(crew.employer.id != period.employer.id || crew.transport.id != period.transport.id || crew.freighter.id != this.order.freighter.id)
      this.changeOrderCrew(crew);
  }

  onToggleCrewsOnMap(): void {
    if(this.isCrewsOnMap) {
      this.hideCrews();
      this.reconnect();
    } else {
      this.showCrews();
    }
  }

  onShowPassportDialog(): void {
    this.passportDialog.showDialogForEmployer(this.order.periods[0].employer);
  }

  onCancelInsuring(): void {
    if(confirm('Подтверждаете отмену страховки?'))
      this.cancelInsurance();
  }

  onShowDeliveryProductDialog(deliveryProduct: DeliveryProduct): void {
    this.showDeliveryProductDialog(deliveryProduct);
  }

  onUpdateDeliveryProduct(deliveryProduct: DeliveryProduct) {
    this.routeEditor.onUpdateDeliveryProduct(deliveryProduct);
  }

  onUpdatedFreighterClient(client: FreighterClient) {
    this.updateFreighterClient(client);
  }

  onShowFreighterSelectDialog(): void {
    this.showFreighterSelectDialog();
  }

  onClickTrackNumber(trackNumber: string): void {
    this.clipboardService.saveToClipboard(trackNumber, `Трек-номер "${trackNumber}" скопирован в буфер обмена`);
  }

  onGenerateDriverAuthLink(): void {
    this.generateDriverAuthLink();
  }
}

<div>
  <table class="table">
    <tbody class="common">
      <tr class="header">
        <th colspan="2">Продолжительность заказа</th>
      </tr>
      <tr>
        <th>Суммарная продолжительность</th>
        <td *ngIf="perMinuteBilling">{{ calculation.minutes }} мин.</td>
        <td *ngIf="!perMinuteBilling">{{ calculation.minutes | roundMinutesToHour }} ч.</td>
      </tr>
      <tr *ngIf="calculation.additional_time + calculation.additional_night_time > 0 && calculationExtensionType != 'by_distance' && calculationExtensionType != 'lgd'">
        <th>Доп. время</th>
        <td *ngIf="perMinuteBilling">{{ calculation.additional_time + calculation.additional_night_time }} мин.</td>
        <td *ngIf="!perMinuteBilling">{{ calculation.additional_time + calculation.additional_night_time | roundMinutesToHour }} ч.</td>
      </tr>
    </tbody>
    <tbody class="transport" *ngIf="calculation.transport_tariff">
      <tr class="header">
        <th colspan="2">Транспорт</th>
      </tr>
      <tr>
        <th>Общее расстояние</th>
        <td>{{ calculation.distance / 1000 | round }} км</td>
      </tr>
      <tr *ngIf="calculation.after_mkad_distance > 0">
        <th>Расстояние за МКАД</th>
        <td>{{ calculation.after_mkad_distance / 1000 | round }} км</td>
      </tr>
      <tr>
        <th>Минимальная стоимость</th>
        <td>{{ calculation.transport_tariff.min_price }} р.<span *ngIf="!perMinuteBilling"> за {{ calculation.transport_tariff.min_hours }} ч.</span></td>
      </tr>
      <tr *ngIf="calculation.after_mkad_cost > 0">
        <th>Стоимость пути за МКАД</th>
        <td *ngIf="calculation.transport_tariff.after_mkad_price != 0">{{ calculation.transport_tariff.after_mkad_price }} р. х {{ calculation.after_mkad_distance / 1000 | round }} км = {{ calculation.after_mkad_cost }} р.</td>
        <td *ngIf="calculation.transport_tariff.after_mkad_price == 0">{{ calculation.after_mkad_cost }} р. за {{ calculation.after_mkad_distance / 1000 | round }} км</td>
      </tr>
      <tr *ngIf="calculation.additional_time_cost + calculation.additional_night_time_cost > 0 && calculationExtensionType != 'by_distance' && calculationExtensionType != 'lgd'">
        <th>Стоимость доп. времени</th>
<!--        <td *ngIf="perMinuteBilling">{{ calculation.additional_time + calculation.additional_night_time }} мин. х {{ calculation.transport_tariff.additional_hour_price / 60 | formatCost }} = {{ calculation.additional_time_cost + calculation.additional_night_time_cost }} р.</td>-->
        <td *ngIf="perMinuteBilling">
          <additional-time-calculation
            [duration]="calculation.additional_time + calculation.additional_night_time"
            [tarifficationPeriod]="transportTarifficationPeriod"
            [tarifficationPeriodPrice]="transportTarifficationPeriodPrice"
            [cost]="calculation.additional_time_cost + calculation.additional_night_time_cost"
          ></additional-time-calculation>
        </td>
        <td *ngIf="!perMinuteBilling">{{ calculation.additional_time + calculation.additional_night_time | roundMinutesToHour }} ч. х {{ calculation.transport_tariff.additional_hour_price }} р. = {{ calculation.additional_time_cost + calculation.additional_night_time_cost }} р.</td>
      </tr>
      <tr *ngIf="calculation.ttk_cost > 0">
        <th>Въезд в ТТК</th>
        <td>{{ calculation.ttk_cost }} р.</td>
      </tr>
      <ng-template ngFor let-area [ngForOf]="calculation.areas || []">
        <tr *ngIf="area.cost > 0">
          <ng-template [ngIf]="area.method == 'point_in'">
            <th>Стоимость точки в зоне "{{ area.area.name }}"</th>
            <td>{{ area.cost }} р.</td>
          </ng-template>

          <ng-template [ngIf]="area.method == 'priority_point_in'">
            <th>Стоимость точки в приоритетной зоне "{{ area.area.name }}"</th>
            <td>{{ area.cost }} р.</td>
          </ng-template>

          <ng-template [ngIf]="area.method == 'distance'">
            <th>Стоимость пути в зоне "{{ area.area.name }}"</th>
            <td>{{ area.cost / area.distance }} р. х {{ area.distance }} км = {{ area.cost }} р.</td>
          </ng-template>
        </tr>
      </ng-template>
      <tr *ngIf="calculation.hydroelevator_cost > 0">
        <th>Стоимость машины с гидролифтом</th>
        <td>{{ calculation.minutes | roundMinutesToHour }} ч. х {{ calculation.transport_tariff.hydroelevator_hour_price }} р. = {{ calculation.hydroelevator_cost }} ч.</td>
      </tr>
    </tbody>
    <tbody *ngIf="calculationExtensionType == 'by_distance'" by-distance-calculation [calculation]="calculationExtension">
    </tbody>
    <tbody *ngIf="calculationExtensionType == 'lgd' && calculationExtensionVersion == 0" lgd-calculation [calculation]="calculationExtension" [destinations]="destinations" [tariff]="transportTariffExtension">
    </tbody>
    <tbody *ngIf="calculationExtensionType == 'lgd' && calculationExtensionVersion > 0" lgd2-calculation [calculation]="calculationExtension" [destinations]="destinations" [tariff]="transportTariffExtension">
    </tbody>
    <tbody class="transport" *ngIf="calculation.transport_tariff">
      <tr class="sum">
        <th>Итоговая стоимость</th>
        <td>{{ calculation.transport_sum_cost }} р.</td>
      </tr>
    </tbody>
    <tbody *ngIf="calculationExtensionType == 'by_zones'" by-zones-calculation [calculation]="calculationExtension">
    </tbody>
    <tbody
      *ngIf="isLoadersCalculationExtensionSupported && loadersCalculationType == 'by_points' && loadersCalculationExtension.cost > 0"
      by-points-loaders-calculation
      [loadersCount]="loadersCalculation.loaders_count"
      [calculation]="loadersCalculationExtension"
      [tariff]="loadersTariffExtension"
    >
    </tbody>
    <tbody class="loaders" *ngIf="calculation.loaders_count > 0 && calculation.loaders_tariff && !isLoadersCalculationExtensionSupported">
      <tr class="header">
        <th colspan="2">Грузчики</th>
      </tr>
      <tr>
        <th>Количество грузчиков</th>
        <td>{{ calculation.loaders_count }}</td>
      </tr>
      <tr>
        <th>Суммарное время работы</th>
        <td *ngIf="perMinuteBilling">{{ calculation.loaders_work_time }} мин.</td>
        <td *ngIf="!perMinuteBilling">{{ calculation.loaders_work_time | roundMinutesToHour }} ч.</td>
      </tr>
      <tr *ngIf="calculation.loaders_additional_time > 0">
        <th>Дополнительное время работы</th>
        <td *ngIf="perMinuteBilling">{{ calculation.loaders_additional_time }} мин.</td>
        <td *ngIf="!perMinuteBilling">{{ calculation.loaders_additional_time | roundMinutesToHour }} ч.</td>
      </tr>
      <tr *ngIf="calculation.loaders_on_the_way_time > 0">
        <th>Время в пути</th>
        <td *ngIf="perMinuteBilling">{{ calculation.loaders_on_the_way_time }} мин.</td>
        <td *ngIf="!perMinuteBilling">{{ calculation.loaders_on_the_way_time | roundMinutesToHour }} ч.</td>
      </tr>
      <tr>
        <th>Базовая минимальная стоимость</th>
        <td>{{ calculation.loaders_tariff.min_price }} р. за {{ calculation.loaders_tariff.min_hours }} ч.<span *ngIf="calculation.loaders_count > 1"> x {{ calculation.loaders_count }}</span> = {{ calculation.loaders_tariff.min_price * calculation.loaders_count }} р.</td>
      </tr>
      <tr *ngIf="calculation.loaders_additional_time > 0">
        <th>Стоимость доп. времени</th>
<!--        <td *ngIf="perMinuteBilling">{{ calculation.loaders_tariff.additional_hour_price / 60 | formatCost }} x {{ calculation.loaders_additional_time }} мин. x {{ calculation.loaders_count }} = {{ calculation.loaders_tariff.additional_hour_price / 60 * calculation.loaders_additional_time * calculation.loaders_count }} р.</td>-->
        <td *ngIf="perMinuteBilling">
          <additional-time-calculation
            [duration]="calculation.loaders_additional_time"
            [tarifficationPeriod]="loadersTarifficationPeriod"
            [tarifficationPeriodPrice]="loadersTarifficationPeriodPrice"
            [cost]="calculation.loaders_additional_time_cost"
          ></additional-time-calculation>
        </td>
        <td *ngIf="!perMinuteBilling">{{ calculation.loaders_tariff.additional_hour_price }} р. x {{ calculation.loaders_additional_time | roundMinutesToHour }} ч.<span *ngIf="calculation.loaders_count > 1"> x {{ calculation.loaders_count }}</span> = {{ calculation.loaders_tariff.additional_hour_price * (calculation.loaders_additional_time | roundMinutesToHour) * calculation.loaders_count }} р.</td>
      </tr>
      <tr *ngIf="calculation.loaders_on_the_way_time > 0">
        <th>Стоимость времени в пути</th>
<!--        <td *ngIf="perMinuteBilling">{{ calculation.loaders_tariff.on_the_way_hour_price / 60 | formatCost }} x {{ calculation.loaders_on_the_way_time }} мин. x {{ calculation.loaders_count }} = {{ calculation.loaders_tariff.on_the_way_hour_price / 60 * calculation.loaders_on_the_way_time * calculation.loaders_count }} р.</td>-->
        <td *ngIf="perMinuteBilling">
          <additional-time-calculation
            [duration]="calculation.loaders_on_the_way_time"
            [tarifficationPeriod]="loadersOnTheWayTarifficationPeriod"
            [tarifficationPeriodPrice]="loadersOnTheWayTarifficationPeriodPrice"
            [cost]="calculation.loaders_on_the_way_cost"
          ></additional-time-calculation>
        </td>
        <td *ngIf="!perMinuteBilling">{{ calculation.loaders_tariff.on_the_way_hour_price }} р. x {{ calculation.loaders_on_the_way_time | roundMinutesToHour }} ч. x {{ calculation.loaders_count }} = {{ calculation.loaders_tariff.on_the_way_hour_price * (calculation.loaders_on_the_way_time | roundMinutesToHour) * calculation.loaders_count }} р.</td>
      </tr>
      <tr>
        <th>Стоимость грузчиков</th>
        <td *ngIf="perMinuteBilling">
          {{ calculation.loaders_tariff.min_price * calculation.loaders_count }} р.
          <span *ngIf="calculation.loaders_additional_time > 0 || calculation.loaders_on_the_way_time > 0">
            <span *ngIf="calculation.loaders_additional_time > 0"> + {{ calculation.loaders_tariff.additional_hour_price / 60 * calculation.loaders_additional_time * calculation.loaders_count | formatCost }}</span>
            <span *ngIf="calculation.loaders_on_the_way_time > 0"> + {{ calculation.loaders_tariff.on_the_way_hour_price / 60 * calculation.loaders_on_the_way_time * calculation.loaders_count | formatCost }}</span> = {{ calculation.loaders_cost }} р.</span>
        </td>
        <td *ngIf="!perMinuteBilling">{{ calculation.loaders_tariff.min_price * calculation.loaders_count }} р. <span *ngIf="calculation.loaders_additional_time > 0"> + {{ calculation.loaders_tariff.additional_hour_price * (calculation.loaders_additional_time | roundMinutesToHour) * calculation.loaders_count }} р.</span> + {{ calculation.loaders_tariff.on_the_way_hour_price * (calculation.loaders_on_the_way_time | roundMinutesToHour) * calculation.loaders_count }} р. = {{ calculation.loaders_cost }} р.</td>
      </tr>
      <tr *ngIf="calculation.services.length > 0">
        <th>
          Доп. услуги
        </th>
        <td><span *ngFor="let service of calculation.services; let i = index;"><span *ngIf="i > 0">, </span>{{ service | loaderService }}</span></td>
      </tr>
      <tr *ngIf="calculation.services.length > 0 && calculation.service_tariff">
        <th>
          Минимальная стоимость доп. услуг
        </th>
        <td>{{ calculation.service_tariff.min_price }} р. за {{ calculation.service_tariff.min_hours }} ч. x {{ calculation.loaders_count }} = {{ calculation.service_tariff.min_price * calculation.loaders_count }} р.</td>
      </tr>
      <tr *ngIf="calculation.services.length > 0 && calculation.loaders_additional_time > 0 && calculation.service_tariff">
        <th>
          Стоимость доп. времени по доп. услугам
        </th>
        <td *ngIf="perMinuteBilling">{{ calculation.service_tariff.additional_hour_price / 60 | formatCost }} х {{ calculation.loaders_additional_time }} мин. x {{ calculation.loaders_count }} = {{ calculation.service_tariff.additional_hour_price / 60 * calculation.loaders_additional_time * calculation.loaders_count }} р.</td>
        <td *ngIf="!perMinuteBilling">{{ calculation.service_tariff.additional_hour_price }} р. х {{ calculation.loaders_additional_time | roundMinutesToHour }} ч. x {{ calculation.loaders_count }} = {{ calculation.service_tariff.additional_hour_price * (calculation.loaders_additional_time | roundMinutesToHour) * calculation.loaders_count }} р.</td>
      </tr>
      <tr *ngIf="calculation.services.length > 0 && calculation.service_cost > 0">
        <th>Итоговая стоимость доп. услуг</th>
        <td *ngIf="perMinuteBilling"><span *ngIf="calculation.loaders_additional_time > 0">{{ calculation.service_tariff.min_price * calculation.loaders_count }} р. + {{ calculation.service_tariff.additional_hour_price / 60 * calculation.loaders_additional_time * calculation.loaders_count | formatCost }} р. = </span>{{ calculation.service_cost }} р.</td>
        <td *ngIf="!perMinuteBilling"><span *ngIf="calculation.loaders_additional_time > 0">{{ calculation.service_tariff.min_price * calculation.loaders_count }} р. + {{ calculation.service_tariff.additional_hour_price * (calculation.loaders_additional_time | roundMinutesToHour) * calculation.loaders_count }} р. = </span>{{ calculation.service_cost }} р.</td>
      </tr>
      <tr class="sum" *ngIf="calculation.service_cost > 0">
        <th>Итоговая стоимость</th>
        <td>{{ calculation.loaders_cost }} р. + {{ calculation.service_cost }} р. = {{ calculation.loaders_sum_cost }} р.</td>
      </tr>
    </tbody>
    <tr class="header">
      <th colspan="2">Работа исполнителей</th>
    </tr>
    <tbody *ngIf="calculationExtensionType == 'vozovoz'" vozovoz-calculation [calculation]="calculationExtension">
    </tbody>
    <tbody *ngIf="calculationExtensionType == 'lgd' && calculationExtensionVersion > 0" lgd2-calculation [calculation]="calculationExtension" [destinations]="destinations" [tariff]="transportTariffExtension" [transportMode]="false">
    </tbody>
    <tr class="sum">
      <th>Стоимость</th>
      <td>{{ calculation.transport_sum_cost || 0 }} р. + {{ calculation.loaders_sum_cost || 0 }} р. = {{ (calculation.executors_cost || calculation.transport_sum_cost || calculation.loaders_sum_cost || 0)|formatCost }}</td>
    </tr>
    <tr *ngIf="calculation.cost_factor && calculation.cost_factor != 1">
      <th>Стоимость с учётом коэффициента</th>
      <td>{{ calculation.executors_cost }} р. * {{ calculation.cost_factor }} = {{ calculation.executors_total_cost }} р.</td>
    </tr>
    <tbody *ngIf="calculation.by_issues_cost != 0">
    <tr class="header">
      <th colspan="2">Проблемы</th>
    </tr>
    <tr>
      <th>Корректировка стоимости</th>
      <td>{{ calculation.by_issues_cost }} р.</td>
    </tr>
    </tbody>
    <tbody *ngIf="calculation.insurances_cost > 0">
    <tr class="header">
      <th colspan="2">Страховка</th>
    </tr>
    <tr>
      <th>Сумма страховки</th>
      <td>{{ calculation.insurances_cost }} р.</td>
    </tr>
    </tbody>
    <tbody>
    <tr class="header">
      <th colspan="2">ИТОГ</th>
    </tr>
    <tr>
      <th>Стоимость заказа</th>
      <td>{{ calculation.cost|formatCost }}</td>
    </tr>
    <tr *ngIf="calculation.raw_cost && calculation.raw_cost != calculation.cost">
      <th>Скорректированная стоимость заказа</th>
      <td>{{ calculation.cost }} р.</td>
    </tr>
    <tr *ngIf="calculation.fixed_discount > 0">
      <th>Фиксированная скидка</th>
      <td>{{ calculation.fixed_discount }} р.</td>
    </tr>
    <tr *ngIf="calculation.discount > 0">
      <th>Процентная скидка</th>
      <td>{{ calculation.discount }} %</td>
    </tr>
    <tr class="sum" *ngIf="calculation.fixed_discount > 0 || calculation.discount > 0">
      <th>Окончательная стоимость</th>
      <td>{{ calculation.total_cost }} р.</td>
    </tr>
    </tbody>
    <tbody *ngIf="report">
    <tr class="header">
      <th colspan="2">Распределение платежей</th>
    </tr>
    <tr>
      <th>Сумма, полученная за заказ</th>
      <td>{{ report.input_sum | formatCost }}</td>
    </tr>
    <tr>
      <th>Комиссия <span *ngIf="isFreightersOrder">компании</span><span *ngIf="!isFreightersOrder">MOVER</span></th>
      <td>{{ report.employer_fee_sum | formatCost }}</td>
    </tr>
    <tr>
      <th>Налог</th>
      <td>{{ report.employer_tax_sum | formatCost }}</td>
    </tr>
    <tr *ngIf="report.employer_wage_sum > 0">
      <th>Выплаты перевозчику</th>
      <td>{{ report.employer_wage_sum | formatCost }}</td>
    </tr>
    <tr *ngIf="!isFreightersOrder">
      <th>Прибыль перевозчика</th>
      <td>{{ report.freighter_sum | formatCost }}</td>
    </tr>
    <tr *ngIf="report.payment_system_sum > 0">
      <th>Комиссия платёжной системы</th>
      <td>{{ report.payment_system_sum | formatCost }}</td>
    </tr>
    <tr>
      <th>Получено <span *ngIf="isFreightersOrder">компанией</span><span *ngIf="!isFreightersOrder">MOVER</span></th>
      <td>{{ report.mover_sum | formatCost }}</td>
    </tr>
    </tbody>
    <tbody *ngIf="calculationExtensionType == 'lgd' && calculationExtensionVersion > 0" lgd2-calculation [calculation]="calculationExtension" [destinations]="destinations" [tariff]="transportTariffExtension" [totalMode]="true">
    </tbody>
  </table>
</div>

import {Account} from "./account";
import {Destination} from "./destination";
import {Image} from "./image";
import {DeliverySchema} from "./delivery-schema";
import {Point} from "./point";
import {DeliverySchedule} from "./delivery-schedule";
import {TaxiSearch} from "./taxi-search";
import {ExtraSearchParams} from "./extra-search-params";
import {OrderFeature} from "./order-feature";
import {CompanyClient} from "./company-client";
import {LegalEntity} from "./legal-entity";
import {OptimizationTaskTransport} from "./optimization-task-transport";
import {OrderDraftEmployerFilter} from "./order-draft-employer-filter";
import {OrderDraftFreighterFilter} from "./order-draft-freighter-filter";
import {City} from "./city";
import {OrderArea} from "./order-area";
import {TransportTariff} from "./transport-tariff";
import {LoaderTariff} from "./loader-tariff";
import {AssemblerTariff} from "./assembler-tariff";
import {LiftingTariff} from "./lifting-tariff";
import {Order} from "./order";
import {DeliveryTrack} from "./delivery-track";
import {Insurance} from "./insurance";
import {FreighterClient} from "./freighter-client";
import {OrderDraftCrewFilter} from "./order-draft-crew-filter";
import {PaymentDistributionSchema} from "./payment-distribution-schema";
import {SelectedPaymentDistribution} from "./selected-payment-distribution";
import {OptimizationTask} from "./optimization-task";

export class OrderDraft {
  id: number;
  features: OrderFeature[];
  comment: string;
  notes: string;
  intercom_dialog: string;
  client: Account;
  cost: number;
  fixed_discount: number;
  percent_discount: number;
  total_cost: number;
  avoid_ttk: boolean;
  destinations: Destination[];
  loaders: number;
  photos: Image[];
  assembly: boolean;
  rigging: boolean;
  minutes: number;
  distance: number;
  out_mkad_distance: number;
  points: Point[];
  delivery: boolean;
  delivery_status: string;
  delivery_schema: DeliverySchema;
  delivery_schedule: DeliverySchedule;
  delivery_company_client: CompanyClient;
  delivery_time_slot_begin: string;
  delivery_time_slot_end: string;
  pay_method: string;
  pay_method_option: string;
  delivery_storehouse_arrival: string;
  created_at: string;
  taxi_search: TaxiSearch;
  extra_search_params: ExtraSearchParams;
  calculation: any;
  auto_accept_taxi: boolean;
  legal_entity: LegalEntity;
  optimization_task: OptimizationTask;
  optimized_transport: OptimizationTaskTransport;
  taxi_schema: string;
  taxi_loaders_schema: string;
  freighter_filters: OrderDraftFreighterFilter[];
  employer_filters: OrderDraftEmployerFilter[];
  crew_filters: OrderDraftCrewFilter[];
  auto_assigment_crew: boolean;
  auto_external_search: boolean;
  manual_search: boolean;
  cargo_storage: boolean;
  freighter_external_id: string;
  external_freighter: string;
  city: City;
  areas: OrderArea[];
  tariffs: TransportTariff[];
  loader_tariffs: LoaderTariff[];
  assembler_tariffs: AssemblerTariff[];
  lifting_tariffs: LiftingTariff[];
  is_freighters_draft: boolean;
  orders: Order[];
  start_delivery_search_at: string;
  test: boolean;
  tags: string[];
  delivery_tracks: DeliveryTrack[];
  insurances: Insurance[];
  pending_search: boolean;
  repeatable_search: boolean;
  freighter_client: FreighterClient;
  payment_distribution_schemas: SelectedPaymentDistribution[];
}
